import styles from './styles/ProfileInfo.module.scss';

import placeholderAvatarIcon from '../../../../assets/icons/avatar-placeholder.svg';
import reviewIcon from '../../../../assets/icons/like-shapes.svg';
import messageIcon from '../../../../assets/icons/message.svg';
// import calendarTickIcon from '../../../../assets/icons/calendar-tick.svg';

import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import request from '../../../../global/helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import { AdditionalServicesText } from '@web-app/common';
import { Skeleton } from '@mui/material';
import { persistChatRoom, setActiveChatRoom } from '../../../../global/reducers/MessageCenterReducer';
import { setCurrentMainPanelSegment, setGlobalLoading } from '../../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../../global/helpers/constants';

const ProfileInfo: React.FC = () => {
  const { userId } = useAppSelector((state) => state.profile);
  const { id } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const isDisabled = useMemo(
    () => userId === undefined || id === userId,
    [userId, id]
  );

  const [profileData, setProfileData] = useState<{
    id: number;
    name?: string;
    email?: string;
    review: number;
    reviewCount: number;
    description?: string;
    userName?: string;
    city?: string;
    address?: string;
    phone?: string;
    personalWebsite?: string;
    services: string[];
    avatarUrl?: string;
  } | null>(null);

  useAsync(async () => {
    if (!userId && !id) return;

    setIsLoading(true);

    const result = await request(
      {
        method: 'GET',
        url: `/v3/private/user/data/profile`,
        params: {
          id: userId ?? id,
        },
      },
      dispatch
    );

    setProfileData({
      id: result.id,
      name: result.name,
      email: result.email,
      review: result.rating,
      reviewCount: result.ratingCount,
      description: result.description,
      userName: result.username,
      city: result.contactData.city,
      address: result.contactData.address,
      phone: result.contactData.phone,
      personalWebsite: result.websiteLink,
      services: result.services,
      avatarUrl: result.avatarFileName,
    });

    setIsLoading(false);

    // set data
  }, [userId, id]);


  const handleOpenChat = async () => {
    dispatch(setGlobalLoading(true));

    const result = await request(
      {
        method: 'POST',
        url: '/v3/private/chat-room',
        data: {
          serviceId: null,
          ownerId: profileData?.id,
        },
      },
      dispatch,
      false,
      () => dispatch(setGlobalLoading(false))
    );

    if (result) {
      dispatch(persistChatRoom(result.chatRoom));
      dispatch(setActiveChatRoom(result.chatRoom.id));

      dispatch(
        setCurrentMainPanelSegment({
          segment: MainPanelSegments.MESSAGES_CENTER,
        })
      );
    }

    dispatch(setGlobalLoading(false));
  };

  if (isLoading) {
    return (
      <div className={styles.profileInfoContainer}>
        <Skeleton height={'300px'} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton height={'300px'} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.profileInfoContainer}>
      <div className={styles.userContainer}>
        <img
          src={profileData?.avatarUrl ?? placeholderAvatarIcon}
          alt='profile'
        />
        <div className={styles.userNameContainer}>
          <h2>{profileData?.userName ?? 'Neturi naudotojo vardo'}</h2>
          <div className={styles.userRatingContainer}>
            <img src={reviewIcon} alt='reviewIcon' />
            {profileData?.review}{' '}
            <span>({profileData?.reviewCount} Atsiliepimų)</span>
          </div>
        </div>
      </div>
      <p className={styles.description}>{profileData?.description}</p>
      <div className={styles.divider} />
      <div className={styles.baseInfo}>
        <p>
          Vardas: <span>{profileData?.name ?? 'Neturi vardo'}</span>
        </p>
        <p>
          Miestas: <span>{profileData?.city ?? 'Nenurodyta'}</span>
        </p>
        <p>
          Adresas: <span>{profileData?.address ?? 'Nenurodyta'}</span>
        </p>
        <p>
          Telefono numeris: <span>{profileData?.phone ?? 'Nenurodyta'}</span>
        </p>
        <p>
          Asmeninis tinklaraštis:{' '}
          <span>{profileData?.personalWebsite ?? 'Nenurodyta'}</span>
        </p>
      </div>
      {Boolean(profileData?.services?.length) && (
        <>
          <div className={styles.divider} />
          <div className={styles.extraServices}>
            <h2>Papildomos paslaugos</h2>
            <div className={styles.servicesContainer}>
              {_.map(profileData?.services ?? [], (service, index) => (
                <div key={index} className={styles.serviceItem}>
                  {
                    //@ts-ignore
                    AdditionalServicesText[Number(service)]
                  }
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className={styles.btnContainer}>
        <button className={styles.messageBtn} disabled={isDisabled} onClick={handleOpenChat}>
          <img src={messageIcon} alt='message' /> Parašyti žinutę
        </button>
        {/* <button className={styles.reserveBtn} disabled={isDisabled}>
          <img src={calendarTickIcon} alt='calendar-tick' /> Rezervuoti vizitą
        </button> */}
      </div>
    </div>
  );
};

export default ProfileInfo;
