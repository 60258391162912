import styles from './styles/ServiceOverview.module.scss';

import maximize from '../../../../assets/icons/maximize-2.svg';

import placeHolderImage from '../../../../assets/images/tattooPHImage.webp';
import avatarPlaceHolder from '../../../../assets/icons/avatar-placeholder.svg';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import _ from 'lodash';
import { useMemo } from 'react';
import { previewModal } from '../../../../global/reducers/ServiceModalReducer';

const ServiceOverview: React.FC = () => {
  const {
    name,
    description,
    city,
    filePreviews,
    address,
    phone,
    price,
    customPrice,
    selectedService,
  } = useAppSelector((state) => state.serviceAdjust);
  const userData = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const preview = useMemo(() => {
    const firstImageFound = filePreviews?.find((file) =>
      file.type.includes('image')
    );

    if (firstImageFound) {
      return firstImageFound.url;
    }

    return placeHolderImage;
  }, [filePreviews]);

  const handleMaximize = () => {
    const firstImageFound = filePreviews?.find((file) =>
      file.type.includes('image')
    );

    // open modal with full preview
    dispatch(
      previewModal({
        title: name,
        description,
        city,
        address,
        phone,
        price: customPrice ? undefined : (price ?? 0) / 100,
        serviceImageUrl: firstImageFound?.url,

        authorId: userData.id ?? undefined,
        authorImageUrl: userData.avatarFileName,
        authorName: userData.name,
        authorRating: userData.rating,
        authorReviewCount: userData.ratingCount,

        files: filePreviews?.map((file) => ({
          fileName: file.url,
          originalFileName: file.object.name,
          mimeType: file.type,
        })),

        services: selectedService ?? [],
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.headline}>
        <span>Peržiūra</span>
        <img alt='random' src={maximize} onClick={handleMaximize} />
      </div>
      <div className={styles.divider} />
      <div
        style={{
          backgroundImage: `url(${preview})`,
        }}
        className={styles.previewImage}
      />
      <div className={styles.previewHeadline}>
        {_.isEmpty(name) ? 'Be pavadinimo' : name}
      </div>
      <div className={styles.previewDescription}>{description}</div>
      <div className={styles.previewFooter}>
        <img alt='avatar' src={avatarPlaceHolder} />
        <div className={styles.footerText}>
          Įkelta <span>{userData.name}</span>
        </div>
        <div className={styles.footerContainer2}>
          {!customPrice && (
            <p className={styles.footerPriceText}>
              <span>€{price ?? 0}</span> / Val
            </p>
          )}
          <p className={styles.footerCity}>{city}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceOverview;
