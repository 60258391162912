import { Modal } from '@mui/material';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../global/GlobalStore';
import {
    ReservationsSegments,
    ReservationStatus,
    resetData,
    setData,
    triggerForceReload,
} from '../../../../../global/reducers/ReservationsReducer';
import ReservationsList from './ReservationsList';
import crossIcon from '../../../../../assets/icons/add.svg';
import clockIcon from '../../../../../assets/icons/clock.svg';
import iconIsolationMod from '../../../../../assets/images/Isolation_Mode.webp';

import styles from './styles/ReservationsPanel.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ADDITIONAL_SERVICES, AdditionalServicesText } from '@web-app/common';
import { handleLightBox } from '../../../../../global/reducers/ServiceModalReducer';
import _ from 'lodash';
import { useAsync, useMount } from 'react-use';
import {
    displayNotification,
    setGlobalLoading,
} from '../../../../../global/reducers/GlobalReducer';
import request from '../../../../../global/helpers/utils';
import { SelectorContainer } from '../../../services-panel/service/Calendar/CalendarInfoEditor';
import { formatToLocaleTimeString } from '../../../services-panel/service-modal/ReserveVisitModal';

const ReservationsPanel: React.FC = () => {
    const dispatch = useAppDispatch();
    useMount(() => {
        dispatch(resetData());
    });

    return (
        <div className={styles.container}>
            <p className={styles.headline}>Vizitai</p>
            <div className={styles.spacer} />
            <ReservationsPanelSelector />
            <ReservationsList />
            <ReservationsEditModal />
        </div>
    );
};

export default ReservationsPanel;

const ReservationsPanelSelector: React.FC = () => {
    const { activeSegment, isLoading } = useAppSelector(
        (state) => state.reservations
    );
    const dispatch = useAppDispatch();

    const handleSelectSegment = (segment: ReservationsSegments) => () => {
        if (isLoading) return;
        dispatch(setData({ activeSegment: segment }));
    };

    return (
        <div className={styles.selector_container}>
            {Object.values(ReservationsSegments).map((segment, index) => (
                <div
                    key={index}
                    onClick={handleSelectSegment(segment)}
                    className={`${styles.selector_btn} ${activeSegment === segment ? styles.selector_btn_active : ''
                        }`}
                >
                    {segment}
                </div>
            ))}
        </div>
    );
};

const ReservationsEditModal: React.FC = () => {
    const { isReservationEditingModalOpen, editingReservation } = useAppSelector(
        (state) => state.reservations
    );
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(
            setData({
                isReservationEditingModalOpen: false,
                editingReservation: undefined,
            })
        );
    };

    const renderContent = useMemo(() => {
        switch (editingReservation?.status) {
            case ReservationStatus.ACCEPTED_RESERVATION:
                // meistras gali keisti laika ir perziureti informacija - negali keisti kainos

                return <AcceptedReservationInfo />;
            case ReservationStatus.PENDING_PAYMENT_RESERVATION:
                // vartotojui modalas su meistro informacija ir kaina
                return <PendingPaymentReservationInfo />;
            case ReservationStatus.PENDING_RESERVATION:
                // klientui modalas su laiko keitimu ir patvirtinimu
                // meistrui modalas su duomenimis -> laiku -> kaina -> patvirtinimu
                return <PendingReservationInfo />;
        }

        return <></>;
    }, [editingReservation?.status]);

    if (!editingReservation) return null;

    return (
        <Modal open={isReservationEditingModalOpen} onClose={handleClose}>
            {renderContent}
        </Modal>
    );
};

const PendingPaymentReservationInfo: React.FC = () => {
    const { editingReservation } = useAppSelector((state) => state.reservations);
    const { name } = useAppSelector((state) => state.user);
    const { globalLoading } = useAppSelector((state) => state.global);

    const priceNominative =
        (editingReservation?.managerProvidedInfo?.price ?? 0) / 100;
    const priceN = Math.trunc(priceNominative);
    const priceCents = (priceNominative - priceN) * 100;
    // const priceCents =
    //     (editingReservation?.managerProvidedInfo?.price ?? 0) - priceNominative;
    const dispatch = useAppDispatch();

    const handleGetPaymentLink = async () => {
        // get payment link
        dispatch(setGlobalLoading(true));

        const result = await request(
            {
                method: 'POST',
                url: `/v3/private/reservations/${editingReservation?.id}/payment`,
            },
            dispatch,
            false,
            () => dispatch(setGlobalLoading(false))
        );

        if (result) {
            window.location.href = result.url;
        }

        dispatch(setGlobalLoading(false));
    };

    const handleClose = () => {
        if (globalLoading) return;

        dispatch(
            setData({
                isReservationEditingModalOpen: false,
                editingReservation: undefined,
            })
        );
    };

    return (
        <div className={styles.modalContainer}>
            <div className={styles.headerContainer}>
                <p>Sveiki !, {name}</p>
                <button className={styles.closeBtn} onClick={handleClose}>
                    <img src={crossIcon} alt='naikinti' />
                </button>
            </div>
            <div className={styles.spacer_bottom} />
            <div className={styles.middleContainer}>
                <p className={styles.headlineText}>
                    Jūsų vizitas rezervuotas, prašome patvirtinti apmokejimą
                </p>
                <p
                    className={`${styles.middleHeadline} ${styles.middleHeadline_center}`}
                >
                    Neatlikus mokejimo, jūsų vizitas bus automatiškai atšauktas po{' '}
                    <b>24</b> valandų nuo vizito sudarymo datos!
                </p>
            </div>
            <div className={styles.middleContainer}>
                <p className={styles.priceText}>
                    {priceNominative}.{priceCents} <span>€</span>
                </p>
            </div>
            {editingReservation?.managerProvidedInfo?.extraInfo && (
                <p className={styles.managerProvidedInfoText}>
                    {editingReservation?.managerProvidedInfo.extraInfo}
                </p>
            )}
            <button
                className={`${styles.modalBtn} ${styles.modalBtn_green}`}
                onClick={handleGetPaymentLink}
                disabled={globalLoading}
            >
                Apmokėti
            </button>
        </div>
    );
};



type PaymentInfoModalInnerDataProps = {
    handleClose: () => void;
    timeFrom: string;
    timeTo: string;
};

export const PaymentInfoModalInnerData: React.FC<PaymentInfoModalInnerDataProps> = ({
    handleClose,
    timeFrom,
    timeTo,
}) => {
    const { name } = useAppSelector((state) => state.user);

    return (
        <div className={styles.modalContainer}>
            <div className={styles.headerContainer}>
                <p>Sveiki !, {name}</p>
                <button className={styles.closeBtn} onClick={handleClose}>
                    <img src={crossIcon} alt='naikinti' />
                </button>
            </div>
            <div className={styles.spacer_bottom} />
            <img src={iconIsolationMod} className={styles.confirm_img} />
            <div className={styles.middleContainer}>
                <p className={styles.headlineText}>Išsiųstas mokėjimas</p>
                <p
                    className={`${styles.middleHeadline} ${styles.middleHeadline_center}`}
                >
                    Mokėjimas sėkmingai išsiųstas, gavėjas kvitą gaus kai mokėjimas bus
                    patvirtintas.
                </p>
            </div>
            <div className={styles.middleContainer}>
                <div className={`${styles.input_selector}`}>
                    <img alt='clock' src={clockIcon} className={styles.invertIcon} />
                    <div className={styles.input_selector_box}>
                        <p className={styles.input_selector_box_headline}>Laikas</p>
                        <p className={styles.input_selector_box_text}>
                            {formatToLocaleTimeString(Number(timeFrom))} -{' '}
                            {formatToLocaleTimeString(Number(timeTo))}
                        </p>
                    </div>
                </div>
            </div>
            <button
                className={`${styles.modalBtn} ${styles.modalBtn_grey}`}
                onClick={handleClose}
            >
                Uždaryti
            </button>
        </div>
    );
};

const AcceptedReservationInfo: React.FC = () => {
    return <div className={styles.modalContainer}></div>;
};

enum ReservationStatusSegments {
    GENERAL_INFO = 'Vizito informacija',
    RESERVATION_TIME = 'Vizito laikas',
    RESERVATION_PRICE = 'Vizito kaina',
}

const PendingReservationInfo: React.FC = () => {
    const { editingReservation } = useAppSelector((state) => state.reservations);
    const dispatch = useAppDispatch();

    const [segment, setSegment] = useState<ReservationStatusSegments>(
        ReservationStatusSegments.GENERAL_INFO
    );

    useMount(() => {
        if (!editingReservation?.userOwnService) {
            setSegment(ReservationStatusSegments.RESERVATION_TIME);
        }
    });

    const btnText = useMemo(() => {
        switch (segment) {
            case ReservationStatusSegments.GENERAL_INFO:
                return 'Toliau';
            case ReservationStatusSegments.RESERVATION_TIME:
                if (!editingReservation?.userOwnService) {
                    return 'Išsaugoti';
                }
                return 'Toliau';
            case ReservationStatusSegments.RESERVATION_PRICE:
                return 'Patvirtinti vizitą';
        }
    }, [segment, editingReservation?.userOwnService]);

    const handleSelectSegment = async (
        currentSegment: ReservationStatusSegments
    ) => {
        switch (currentSegment) {
            case ReservationStatusSegments.GENERAL_INFO:
                setSegment(ReservationStatusSegments.RESERVATION_TIME);
                break;
            case ReservationStatusSegments.RESERVATION_TIME:
                if (!editingReservation?.userOwnService) {
                    dispatch(setGlobalLoading(true));
                    const newTime = editingReservation?.timeFrom; // get new time

                    const result = await request(
                        {
                            method: 'PUT',
                            url: `v3/private/reservations/${editingReservation?.id}`,
                            data: {
                                timeFrom: newTime,
                            },
                        },
                        dispatch,
                        false,
                        () => dispatch(setGlobalLoading(false))
                    );

                    if (result) {
                        dispatch(triggerForceReload());
                        dispatch(
                            setData({
                                isReservationEditingModalOpen: false,
                                editingReservation: undefined,
                            })
                        );
                    } else {
                        dispatch(
                            displayNotification({
                                message:
                                    'Nepavyko išsaugoti laiko, tikriausiai laikas jau užimtas',
                                severity: 'error',
                            })
                        );
                        dispatch(triggerForceReload());
                    }
                    break;
                }

                if (
                    editingReservation?.userOwnService &&
                    !_.isEmpty(editingReservation?.timeTo)
                ) {
                    setSegment(ReservationStatusSegments.RESERVATION_PRICE);
                    break;
                } else {
                    dispatch(
                        displayNotification({
                            message: 'Prašome pasirinkti vizito pabaigos laiką',
                            severity: 'error',
                        })
                    );
                }
                break;
            case ReservationStatusSegments.RESERVATION_PRICE:
                dispatch(setGlobalLoading(true));
                // setup manager provided info and price
                const info = editingReservation?.managerProvidedInfo;
                const timeTo = editingReservation?.timeTo;

                const result = await request(
                    {
                        method: 'POST',
                        url: `v3/private/reservations/${editingReservation?.id}/confirm`,
                        data: {
                            price: info?.price,
                            extraInfo: info?.extraInfo,
                            timeTo: timeTo,
                        },
                    },
                    dispatch,
                    false,
                    () => {
                        dispatch(setGlobalLoading(false));
                    }
                );

                if (result) {
                    dispatch(triggerForceReload());
                    dispatch(
                        setData({
                            isReservationEditingModalOpen: false,
                            editingReservation: undefined,
                        })
                    );
                }

                if (result.status === 402) {
                    dispatch(
                        displayNotification({
                            message:
                                'Prieš patvirtinant vizitą, privaloma pridėti apmokėjimo informaciją',
                            severity: 'error',
                        })
                    );
                }
                break;
        }

        dispatch(setGlobalLoading(false));
    };

    const handleClose = () => {
        dispatch(
            setData({
                isReservationEditingModalOpen: false,
                editingReservation: undefined,
            })
        );
    };

    return (
        <div className={styles.modalContainer}>
            <div className={styles.headerContainer}>
                <p>{segment}</p>
                <button className={styles.closeBtn} onClick={handleClose}>
                    <img src={crossIcon} alt='naikinti' />
                </button>
            </div>
            <div className={styles.spacer_bottom} />
            {segment === ReservationStatusSegments.GENERAL_INFO && (
                <GeneralInfoSegment />
            )}
            {segment === ReservationStatusSegments.RESERVATION_TIME && (
                <SelectTimeSegment />
            )}
            {segment === ReservationStatusSegments.RESERVATION_PRICE && (
                <PriceSegment />
            )}
            <button
                className={styles.modalBtn}
                onClick={() => handleSelectSegment(segment)}
            >
                {btnText}
            </button>
        </div>
    );
};

const SelectTimeSegment: React.FC = () => {
    const { editingReservation } = useAppSelector((state) => state.reservations);
    const dispatch = useAppDispatch();
    const [availableTimes, setAvailableTimes] = useState<number[] | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElTo, setAnchorElTo] = useState<null | HTMLElement>(null);

    useAsync(async () => {
        const result = await request(
            {
                method: 'GET',
                url: `v3/private/reservations/${editingReservation?.id}/available-times`,
            },
            dispatch,
            false,
            () => setAvailableTimes(null)
        );

        setAvailableTimes(result.times);
    }, [editingReservation?.id]);

    const handleOpenSelector = (
        e: React.MouseEvent<HTMLElement>,
        from = true
    ) => {
        if (from) setAnchorEl(e.currentTarget);
        else {
            setAnchorElTo(e.currentTarget);
        }
    };

    const handleSelectTime = (_: React.MouseEvent, time: number, from = true) => {
        if (editingReservation) {
            if (from) {
                if (
                    editingReservation.timeTo &&
                    Number(editingReservation.timeTo) < time
                ) {
                    dispatch(
                        displayNotification({
                            message:
                                'Vizito pabaigos laikas negali būti ankstesnis nei pradžios',
                            severity: 'error',
                        })
                    );
                    return;
                }

                if (
                    editingReservation.timeTo &&
                    Number(editingReservation.timeTo) === time
                ) {
                    dispatch(
                        displayNotification({
                            message: 'Vizito pradžios ir pabaigos laikai negali sutapti',
                            severity: 'error',
                        })
                    );
                    return;
                }

                dispatch(
                    setData({
                        editingReservation: {
                            ...editingReservation,
                            timeFrom: String(time),
                        },
                    })
                );
            }

            if (!from) {
                if (
                    editingReservation.timeFrom &&
                    Number(editingReservation.timeFrom) > time
                ) {
                    dispatch(
                        displayNotification({
                            message:
                                'Vizito pradžios laikas negali būti vėlesnis nei pabaigos',
                            severity: 'error',
                        })
                    );
                    return;
                }

                if (
                    editingReservation.timeFrom &&
                    Number(editingReservation.timeFrom) === time
                ) {
                    dispatch(
                        displayNotification({
                            message: 'Vizito pradžios ir pabaigos laikai negali sutapti',
                            severity: 'error',
                        })
                    );
                    return;
                }

                dispatch(
                    setData({
                        editingReservation: { ...editingReservation, timeTo: String(time) },
                    })
                );
            }
        }

        setAnchorEl(null);
        setAnchorElTo(null);
    };

    return (
        <>
            {availableTimes !== null && (
                <>
                    <div className={styles.middleContainer}>
                        <p className={styles.middleHeadline}>Rezervuotas vizitas</p>
                        <div>
                            <div
                                className={`${styles.input_selector}`}
                                onClick={handleOpenSelector}
                            >
                                <img
                                    alt='clock'
                                    src={clockIcon}
                                    className={styles.invertIcon}
                                />
                                <div className={styles.input_selector_box}>
                                    <p className={styles.input_selector_box_headline}>Laikas</p>
                                    <p className={styles.input_selector_box_text}>
                                        {formatToLocaleTimeString(
                                            Number(editingReservation?.timeFrom)
                                        )}
                                    </p>
                                </div>
                            </div>
                            <SelectorContainer
                                anchorEl={anchorEl}
                                handleClose={() => setAnchorEl(null)}
                                handleSelectionOption={handleSelectTime}
                                availableTimes={availableTimes}
                            />
                        </div>
                    </div>

                    {Boolean(editingReservation?.userOwnService) && (
                        <div className={styles.middleContainer}>
                            <p className={styles.middleHeadline}>
                                Pasirinkite vizito pabaigos laiką
                            </p>
                            <div>
                                <div
                                    className={`${styles.input_selector}`}
                                    onClick={(e) => handleOpenSelector(e, false)}
                                >
                                    <img
                                        alt='clock'
                                        src={clockIcon}
                                        className={styles.invertIcon}
                                    />
                                    <div className={styles.input_selector_box}>
                                        <p className={styles.input_selector_box_headline}>Laikas</p>
                                        <p className={styles.input_selector_box_text}>
                                            {formatToLocaleTimeString(
                                                Number(editingReservation?.timeTo)
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <SelectorContainer
                                    anchorEl={anchorElTo}
                                    handleClose={() => setAnchorElTo(null)}
                                    handleSelectionOption={(e, v) =>
                                        handleSelectTime(e, v, false)
                                    }
                                    availableTimes={availableTimes}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.invisible_spacer} />
                </>
            )}
        </>
    );
};

const GeneralInfoSegment: React.FC = () => {
    const { editingReservation } = useAppSelector((state) => state.reservations);
    const dispatch = useAppDispatch();

    const handleOpenLightbox = () => {
        dispatch(
            handleLightBox({
                isOpen: true,
                files: _.map(editingReservation?.reservationInfo?.fileUrl, (url) => ({
                    fileName: url,
                    originalFileName: 'Pavyzdys',
                    mimeType: 'image/jpeg',
                })),
            })
        );
    };

    return (
        <>
            <div className={styles.middleContainer}>
                <p className={styles.middleHeadline}>Reikalinga paslauga</p>
                <p className={styles.middleServiceName}>
                    {Boolean(editingReservation?.reservationInfo.serviceName)
                        ? AdditionalServicesText[
                        (editingReservation?.reservationInfo
                            .serviceName as unknown as ADDITIONAL_SERVICES) ??
                        ADDITIONAL_SERVICES.OTHER_SERVICES
                        ]
                        : ''}
                </p>
            </div>
            <div className={styles.spacer_bottom} />
            <div className={styles.middleContainer}>
                <p className={styles.middleHeadline}>Papildoma informacija</p>
                <p className={styles.middleServiceInfo}>
                    {editingReservation?.reservationInfo.additionalInfo ?? ''}
                </p>
            </div>
            <div className={styles.spacer_bottom} />
            <div className={styles.middleContainer}>
                <p className={styles.middleHeadline}>Failai ir eskizai</p>
                <div className={styles.fileContainer}>
                    {editingReservation?.reservationInfo.fileUrl.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt='file'
                            onClick={() => handleOpenLightbox()}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

const PriceSegment: React.FC = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const measurerRef = useRef<HTMLSpanElement>(null);
    const { editingReservation } = useAppSelector((state) => state.reservations);
    const dispatch = useAppDispatch();

    useMount(() => {
        if (
            inputRef.current &&
            editingReservation?.serviceData?.price !== undefined
        ) {
            let occupiedTime =
                Number(editingReservation?.timeTo) -
                Number(editingReservation?.timeFrom);

            if (Math.abs((occupiedTime % 1) - 0.3) < 1e-10) {
                occupiedTime += 0.2;
            }

            const recomendedPrice = Math.floor(
                (editingReservation?.serviceData.price / 100) * occupiedTime
            );

            inputRef.current.value = String(recomendedPrice) ?? '0.00';
            setInputValue(String(recomendedPrice) ?? '0.00');

            dispatch(
                setData({
                    editingReservation: {
                        ...editingReservation,
                        managerProvidedInfo: {
                            ...editingReservation.managerProvidedInfo,
                            price: recomendedPrice * 100, // in cents
                        },
                    },
                })
            );
        }
    });

    useEffect(() => {
        if (!inputRef.current || !measurerRef.current) return;

        inputRef.current.style.width = '100%';

        function resizeInput() {
            if (!measurerRef.current || !inputRef.current) return;

            measurerRef.current.textContent = inputRef.current?.value || ' ';
            inputRef.current.style.width = `${measurerRef.current.offsetWidth * 2.5
                }px`;
        }

        inputRef.current?.addEventListener('input', resizeInput);

        return () => {
            inputRef.current?.removeEventListener('input', resizeInput);
        };
    }, [inputRef, measurerRef]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!editingReservation) return;

        const value = e.target.value;

        const v = Number(value) * 100;

        if (isNaN(v) && value !== '') {
            return;
        }

        if (value === '' && measurerRef.current) {
            measurerRef.current.innerText = '0.00';
        }

        setInputValue(value);
        dispatch(
            setData({
                editingReservation: {
                    ...editingReservation,
                    managerProvidedInfo: {
                        ...editingReservation.managerProvidedInfo,
                        price: Math.floor((Number(value) ?? 0) * 100),
                    },
                },
            })
        ); // in cents
    };

    const handleInputInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!editingReservation) return;

        dispatch(
            setData({
                editingReservation: {
                    ...editingReservation,
                    managerProvidedInfo: {
                        price: editingReservation?.managerProvidedInfo?.price ?? 0, // in cents
                        extraInfo: e.currentTarget.value,
                    },
                },
            })
        );
    };

    return (
        <>
            <div className={styles.middleContainer}>
                <form>
                    <div className={styles.input_container}>
                        <input
                            value={inputValue}
                            ref={inputRef}
                            className={styles.input_v2_price}
                            type='text'
                            placeholder='0.00'
                            onChange={handleInput}
                        />
                        <span className={styles.input_v2_price_mark}>€</span>
                        <span className={styles.measurer_style} ref={measurerRef} />
                    </div>
                    <p className={styles.input_underline}>Irašykite paslaugos kainą</p>
                    <input
                        className={styles.input_extra_info}
                        type='text'
                        placeholder='Papildoma informacija (nebūtina)'
                        onChange={handleInputInfo}
                    />
                </form>
            </div>
        </>
    );
};
