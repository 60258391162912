import { useMemo, useState } from 'react';
import styles from './styles/ProfileSettings.module.scss';
import _ from 'lodash';
import EditProfile from './EditProfile';
import EditPassword from './EditPassword';
import EditServices from './EditServices';
import DeactivateAccount from './DeactivateAccount';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import { USER_PERMISSIONS } from '@web-app/common';
import ReservationsPanel from './reservations-panel/ReservationsPanel';
import { useAsync } from 'react-use';
import { setGlobalLoading } from '../../../../global/reducers/GlobalReducer';
import request from '../../../../global/helpers/utils';

export enum ProfileSettingsSegments {
  PROFILE = 'Profilis',
  PASSWORD = 'Slaptažodis',
  SERVICES = 'Paslaugos',
  PAYMENT = 'Mokėjimo būdai', // -- kol kas nereikalingas
  DEACTIVATE_ACCOUNT = 'Deaktyvuoti paskyrą',

  // Rezervacijos
  RESERVATIONS = 'Vizitai',

  // Subscription actions
  MANAGE_TEAM = 'Pridėti meistrą',
  SUBSCRIPTION_PLAN = 'Keisti InkSpiration planą'
}

const ProfileSettings: React.FC = () => {
  const [currentSegment, setCurrentSegment] = useState(
    ProfileSettingsSegments.PROFILE
  );

  const { permissions } = useAppSelector((state) => state.user);

  const availableSegments = useMemo(() => {
    const isWorker = _.some(permissions, (p) => p === USER_PERMISSIONS.worker);

    const segments: ProfileSettingsSegments[] = [];

    segments.push(ProfileSettingsSegments.PROFILE);
    segments.push(ProfileSettingsSegments.PASSWORD);
    if (isWorker) segments.push(ProfileSettingsSegments.SERVICES);

    segments.push(ProfileSettingsSegments.PAYMENT); // button

    segments.push(ProfileSettingsSegments.RESERVATIONS);

    segments.push(ProfileSettingsSegments.DEACTIVATE_ACCOUNT);

    // TODO: Implement - available when user has active subscription
    if (false) {
      segments.push(ProfileSettingsSegments.MANAGE_TEAM);
      segments.push(ProfileSettingsSegments.SUBSCRIPTION_PLAN);
    }

    return segments;
  }, [permissions]);

  const segmentElement = useMemo(() => {
    switch (currentSegment) {
      case ProfileSettingsSegments.PROFILE:
        return <EditProfile />;
      case ProfileSettingsSegments.PASSWORD:
        return <EditPassword />;
      case ProfileSettingsSegments.SERVICES:
        return <EditServices />;
      case ProfileSettingsSegments.DEACTIVATE_ACCOUNT:
        return <DeactivateAccount />;
      case ProfileSettingsSegments.RESERVATIONS:
        return <ReservationsPanel />;
      case ProfileSettingsSegments.PAYMENT:
        return <PaymentMethodsLinkModule callBack={() => setCurrentSegment(ProfileSettingsSegments.PROFILE)} />;

      // Subscription actions

      default:
        return null;
    }
  }, [currentSegment]);

  return (
    <div className={styles.panel}>
      <div className={styles.leftContailer}>
        {_.map(availableSegments, (e, index) => (
          <button
            key={index}
            onClick={() => setCurrentSegment(e)}
            className={`${styles.navBtn} ${e === currentSegment ? styles.navBtn_active : ''
              }`}
          >
            {e}
          </button>
        ))}
      </div>
      <div className={styles.rightContailer}>{segmentElement}</div>
    </div>
  );
};

export default ProfileSettings;


const PaymentMethodsLinkModule: React.FC<{ callBack: () => void }> = ({ callBack }) => {
  const dispatch = useAppDispatch();
  useAsync(async () => {
    dispatch(setGlobalLoading(true));

    const result = await request({
      url: '/v3/private/stripe-portal',
      method: 'GET',
    }, dispatch, false, () => {
      setGlobalLoading(false);
      callBack();
    });

    if (result.url) {
      window.location.href = result.url;
      callBack();
    }
    dispatch(setGlobalLoading(false));
  }, []);

  return <></>
}