import { useAppSelector } from '../../../global/GlobalStore'
import { useMount } from 'react-use';
import { Skeleton } from '@mui/material';

export default function AdjustSubscription() {
    const { billingPortalUrl } = useAppSelector((state) => state.subscriptionPanel);

    useMount(() => {
        if (billingPortalUrl) {
            window.location.href = billingPortalUrl;
        }

    });

    return (
        <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <br />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <br />
            <Skeleton />
            <Skeleton />
        </div>
    )
}
