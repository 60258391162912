/// <reference types="redux-persist" />

import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';
import { globalSlice } from './reducers/GlobalReducer';
import { FLUSH, PAUSE, PERSIST, persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { userSlice } from './reducers/UserReducer';
import { serviceListSlice } from './reducers/ServiceListReducer';
import { serviceAdjustSlice } from './reducers/ServiceAdjustReducer';
import { serviceModalSlice } from './reducers/ServiceModalReducer';
import { profileSlice } from './reducers/ProfileReducer';
import { notificationSlice } from './reducers/NotificationReducer';
import { messageCenterSlice } from './reducers/MessageCenterReducer';
import { reservationsSlice } from './reducers/ReservationsReducer';
import { subscriptionPanelSlice } from './reducers/SubscriptionReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistGlobalReducer = persistReducer(persistConfig, globalSlice.reducer);

export const globalStore = configureStore({
  reducer: {
    global: persistGlobalReducer,
    user: userSlice.reducer,
    serviceList: serviceListSlice.reducer,
    serviceAdjust: serviceAdjustSlice.reducer,
    serviceModal: serviceModalSlice.reducer,
    profile: profileSlice.reducer,
    notification: notificationSlice.reducer,
    messageCenter: messageCenterSlice.reducer,
    reservations: reservationsSlice.reducer,
    subscriptionPanel: subscriptionPanelSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PERSIST, PAUSE],
      },
    }),
});

export const persistor = persistStore(globalStore);

export const purgeData = async () => await persistor.purge();

export type RootState = ReturnType<typeof globalStore.getState>;
export const useAppDispatch: () => typeof globalStore.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
