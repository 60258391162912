import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SubscriptionWorkerData = {
  id: number;
  name: string;
  avatarUrl: string;
};

export interface SubscriptionPanelState {
  //   userId?: number; // user id - undefined if not selected user
  isSubscriptionActive?: boolean;
  billingPortalUrl?: string | null;

  // adjust subscription workers

  // only owner can add workers to subscription
  isSubscriptionOwner?: boolean;

  // list of workers
  workers?: SubscriptionWorkerData[];
}

const initialState: SubscriptionPanelState = {};

export const subscriptionPanelSlice = createSlice({
  name: 'subscriptionPanel',
  initialState: initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<Partial<SubscriptionPanelState>>
    ) => {
      state = { ...state, ...action.payload };

      return state;
    },

    // worker actions
    addWorker: (state, action: PayloadAction<SubscriptionWorkerData>) => {
      state.workers = [...(state.workers || []), action.payload];

      return state;
    },

    removeWorker: (state, action: PayloadAction<{ id: number }>) => {
      state.workers = state.workers?.filter(
        (worker) => worker.id !== action.payload.id
      );

      return state;
    },
  },
});

export default subscriptionPanelSlice.reducer;
export const { setData, addWorker, removeWorker } =
  subscriptionPanelSlice.actions;
