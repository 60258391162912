import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ADDITIONAL_SERVICES } from '@web-app/common';
import { TimeChangeType } from '../../pages/admin/services-panel/service/Calendar/CalendarInfoEditor';

export interface FilePreview {
  url: string;
  type: string;
  object: File;
}

export interface ServiceAdjustState {
  serviceId?: number; // when editing existing service only

  // base
  name?: string;
  description?: string;

  // contact
  phone?: string;
  email?: string;
  city?: string;
  address?: string;

  // files
  filePreviews?: FilePreview[];

  // price
  price?: number;
  customPrice?: boolean;

  // additional services
  selectedService?: ADDITIONAL_SERVICES[];

  calendarData?: {
    date: string; // YYYY-MM-DD
    hoursMark: number[]; // 9.30, 10.0, 10.30 // by 30 min mark
  }[];

  initialData: {
    serviceId?: number; // when editing existing service only

    // base
    name?: string;
    description?: string;

    // contact
    phone?: string;
    email?: string;
    city?: string;
    address?: string;

    // files
    filePreviews?: FilePreview[];

    // price
    price?: number;
    customPrice?: boolean;

    // additional services
    selectedService?: ADDITIONAL_SERVICES[];

    calendarData?: {
      date: string; // YYYY-MM-DD
      hoursMark: number[]; // 9.30, 10.0, 10.30 // by 30 min mark
    }[];
  };

  adjustCalendarModalOpen?: boolean;

  // misc stuff for calendar editor
  calendarEditorType?: TimeChangeType;

  selectedMonth?: number;
  selectedYear?: number;

  calendarEditorData?: {
    dates: any;
    nationalDaysExcluded?: boolean;
  };
}

const initialState: ServiceAdjustState = {
  initialData: {
    serviceId: undefined,

    // base
    name: '',
    description: '',

    // contact
    phone: '',
    email: '',
    city: '',
    address: '',

    filePreviews: [],

    price: 0,
    customPrice: false,

    selectedService: [],

    calendarData: [],
  },

  calendarEditorType: TimeChangeType.SELECTED_DAYS,
};

export const serviceAdjustSlice = createSlice({
  name: 'serviceAdjust',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ServiceAdjustState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    resetData: (state) => {
      state = { ...state, ...state.initialData };
      return state;
    },
    handleAdjustCalendarModal: (state) => {
      return {
        ...state,
        adjustCalendarModalOpen: !state.adjustCalendarModalOpen,
      };
    },
  },
});

export default serviceAdjustSlice.reducer;
export const { setData, resetData, handleAdjustCalendarModal } =
  serviceAdjustSlice.actions;
