import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ReservationsSegments {
  ALL = 'Visi vizitai',
  ACCEPTED = 'Patvirtinti',
  UNKNOWN = 'Nepatvirtinti',
}

export enum ReservationStatus {
  INVALID_RESERVATION = 'INVALID',
  PENDING_RESERVATION = 'PENDING',
  ACCEPTED_RESERVATION = 'ACCEPTED',
  REJECTED_RESERVATION = 'REJECTED',

  CANCELED_RESERVATION = 'CANCELED',
  FINISHED_RESERVATION = 'FINISHED',

  PENDING_PAYMENT_RESERVATION = 'PENDING_PAYMENT',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
}

export type Reservation = {
  id: number;

  serviceData: {
    name: string;
    address: string;
    price?: number; // in cents
  };

  date: string;
  timeFrom: string;
  timeTo?: string;
  status: ReservationStatus;

  userOwnService: boolean;

  user: {
    name: string;
    avatarUrl?: string;
  };

  reservationInfo: {
    serviceName?: string;
    additionalInfo: string;
    fileUrl: string[];
  };

  managerProvidedInfo?: {
    price: number; // in cents
    extraInfo?: string;
  };
};

export interface ReservationsState {
  activeSegment: ReservationsSegments;
  isLoading: boolean;

  isReservationEditingModalOpen: boolean;
  editingReservation?: Reservation;

  forceReload: boolean;
}

const initialState: ReservationsState = {
  activeSegment: ReservationsSegments.ALL,
  isLoading: true,

  isReservationEditingModalOpen: false,
  forceReload: false,
};

export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ReservationsState>>) => {
      return { ...state, ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
    triggerForceReload: (state) => {
      return { ...state, forceReload: !state.forceReload };
    },
  },
});

export default reservationsSlice.reducer;
export const { setData, resetData, triggerForceReload } =
  reservationsSlice.actions;
