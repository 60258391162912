import React, { useState } from 'react';

import styles from '../LoginPage.module.scss';

import profile_icon from '../../../../assets/icons/profile.svg';
import mail_icon from '../../../../assets/icons/sms.svg';
import key_icon from '../../../../assets/icons/key.svg';
import { useAppDispatch } from '../../../../global/GlobalStore';
import { setAuthenticationData } from '../../../../global/reducers/GlobalReducer';
import { FormSegment } from '../LoginPage';
import { USER_PERMISSIONS } from '@web-app/common';
// import check_purple from '../../../../assets/icons/check_purple.svg';
import request from '../../../../global/helpers/utils';
import CircleLoader from '../../../../components/Loader/CircleLoader';

const RegisterForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  //eslint-disable-next-line
  const [userType, setUserType] = useState<USER_PERMISSIONS>(
    USER_PERMISSIONS.user
  );

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    setIsLoading(true);
    try {
      await request(
        {
          method: 'POST',
          url: '/auth/register',
          data: {
            name: name,
            email: email,
            password: password,
            userType: userType,
          },
        },
        dispatch,
        false,
        () => setIsLoading(false)
      );

      // on success
      dispatch(
        setAuthenticationData({
          segment: FormSegment.VERIFY_EMAIL,
          email: email,
        })
      );
    } catch (e) {
      // indicate somewhere is error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p className={styles.title}>Sukurti naują paskyrą</p>

      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${profile_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Jūsų vardas'
            type='text'
            id='name'
            value={name}
            onChange={handleNameChange}
          />
        </div>

        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${mail_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Jūsų el. paštas'
            type='email'
            id='register-email'
            value={email}
            autoComplete='off'
            aria-autocomplete='none'
            onChange={handleEmailChange}
          />
        </div>

        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${key_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Slaptažodis'
            type='password'
            id='register-password'
            autoComplete='off'
            aria-autocomplete='none'
            value={password}
            onChange={handlePasswordChange}
          />
        </div>

        {/* <div className={styles.checkBoxContainer}>
          <div
            className={`${styles.checkboxItem} ${
              userType === USER_PERMISSIONS.worker
                ? styles.checkboxItem_active
                : ''
            }`}
            onClick={() => setUserType(USER_PERMISSIONS.worker)}
          >
            <div
              style={{
                backgroundImage: `url(${check_purple})`,
                display:
                  userType === USER_PERMISSIONS.worker ? 'block' : 'none',
              }}
              className={styles.checkboxIcon}
            />
            <p className={styles.checkboxUnderline}>Esu</p>
            <p className={styles.checkboxText}>Meistras</p>
          </div>
          <div
            className={`${styles.checkboxItem} ${
              userType === USER_PERMISSIONS.user
                ? styles.checkboxItem_active
                : ''
            }`}
            onClick={() => setUserType(USER_PERMISSIONS.user)}
          >
            <div
              style={{
                backgroundImage: `url(${check_purple})`,
                display: userType === USER_PERMISSIONS.user ? 'block' : 'none',
              }}
              className={styles.checkboxIcon}
            />
            <p className={styles.checkboxUnderline}>Esu</p>
            <p className={styles.checkboxText}>Klientas</p>
          </div>
        </div> */}
        <button type='submit' className={styles.submitBtn}>
          {isLoading ? <CircleLoader width={50} /> : 'Registruotis'}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
