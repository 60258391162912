import styles from './styles/ServicesList.module.scss';

import plustMoreIcon from '../../../../assets/icons/add-circle.svg';
import sortIcon from '../../../../assets/icons/candle-2.svg';
import { Icon, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import _, { debounce } from 'lodash';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import {
  reset,
  setData,
  SORT_OPTIONS,
} from '../../../../global/reducers/ServiceListReducer';

import heartIcon from '../../../../assets/icons/heart.svg';
import messageIcon from '../../../../assets/icons/message.svg';
import eyeIcon from '../../../../assets/icons/eye.svg';
import {
  setCurrentMainPanelSegment,
  setGlobalLoading,
} from '../../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../../global/helpers/constants';
import { useMount } from 'react-use';
import request from '../../../../global/helpers/utils';

import placeholderImage from '../../../../assets/images/tattooPHImage.webp';
import { openModal } from '../../../../global/reducers/ServiceModalReducer';
import { AnimatePresence, motion } from 'framer-motion';
import { USER_PERMISSIONS } from '@web-app/common';
import {
  persistChatRoom,
  setActiveChatRoom,
} from '../../../../global/reducers/MessageCenterReducer';

const ServicesList: React.FC = () => {
  const { orderBy, searchResults } = useAppSelector(
    (state) => state.serviceList
  );
  const { permissions } = useAppSelector((state) => state.user);

  const isWorker = useMemo(
    () => _.some(permissions, (p) => p === USER_PERMISSIONS.worker),
    [permissions]
  );

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNewServiceClick = () => {
    dispatch(
      setCurrentMainPanelSegment({
        segment: MainPanelSegments.ADJUST_SERVICE,
      })
    );
  };

  useMount(async () => {
    dispatch(setGlobalLoading(true));
    dispatch(reset());
    const filtersResponse = await request({
      method: 'GET',
      url: '/v3/private/service/search/filters',
    });

    if (filtersResponse.data) {
      const prices: number[] = filtersResponse.data.prices;

      const selected = {
        min: Math.min(...prices, 0),
        max: Math.max(...prices, 0),
      };
      dispatch(
        setData({
          cityOptions: filtersResponse.data.cities,
          experienceOptions: filtersResponse.data.experience,
          priceOptions: prices,
          selectedPriceRange: selected,
          filtersLoaded: true,
        })
      );
    }

    const rememberedServices: any[] = await request({
      method: 'GET',
      url: '/v3/private/remembered-services',
    });

    if (rememberedServices) {
      dispatch(
        setData({
          rememberedServiceIds: rememberedServices.map((r) => r.id),
        })
      );
    }

    dispatch(setGlobalLoading(false));
  });

  return (
    <div className={styles.container}>
      <p className={styles.headline}>
        Meistrų skelbimai{' '}
        {searchResults !== undefined && <span>{searchResults}</span>}
      </p>
      <div className={styles.TopbtnContainer}>
        {isWorker ? (
          <button className={styles.btnUpload} onClick={handleNewServiceClick}>
            <img src={plustMoreIcon} alt='plus_more' /> Įkelti skelbimą
          </button>
        ) : (
          <div />
        )}
        <button className={styles.btnSort} onClick={handleAnchorClick}>
          {orderBy}
          <img src={sortIcon} alt='sort-icon' />
        </button>
        <Menu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        >
          {_.map(SORT_OPTIONS, (option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                dispatch(setData({ orderBy: option }));
                setAnchorEl(null);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Services />
    </div>
  );
};

export default ServicesList;

type Service = {
  id: number;
  imageUrl: string;
  name: string;
  description: string;
  price: number | undefined;
  city: string;
  createdOn: Date;
};

const Services: React.FC = () => {
  const {
    orderBy,
    selectedCity,
    selectedExperience,
    selectedPriceRange,
    currentPage,
    searchResults,
    selectedUserId,
    selectedUserName,
    filtersLoaded,
    listLoaded,
  } = useAppSelector((state) => state.serviceList);
  const dispatch = useAppDispatch();

  const [list, setList] = useState<Service[]>([]);

  useEffect(() => {
    const fetchData = debounce(async () => {
      if (!filtersLoaded) return;

      const response = await request(
        {
          method: 'POST',
          url: '/v3/private/service/search',
          data: {
            orderBy: orderBy,
            city: selectedCity,
            experience: selectedExperience,
            userId: selectedUserId,
            name: selectedUserName,
            username: selectedUserName, // temp while partnership is disabled
            priceRange: selectedPriceRange && {
              min: selectedPriceRange?.min,
              max: selectedPriceRange?.max,
            },
            page: undefined,
          },
        },
        dispatch
      );

      if (response) {
        const buildedData = _.map(response.data, (service) => ({
          id: service.id,
          imageUrl: service.imageUrl ?? placeholderImage,
          name: service.name,
          description: service.description,
          price: service?.data?.customPrice ? undefined : (service.price ?? 0) / 100,
          city: service.contactData.city,
          createdOn: new Date(service.createdOn),
        }));

        setList(buildedData);
        if (response.servicesCount !== undefined) {
          dispatch(
            setData({
              searchResults: response.servicesCount,
              currentPage: response.page,
            })
          );
        }

        dispatch(setData({ listLoaded: true }));
      }
    }, 1000);

    fetchData();

    return () => {
      fetchData.cancel();
    };
  }, [
    orderBy,
    selectedCity,
    selectedExperience,
    selectedPriceRange,
    currentPage,
    filtersLoaded,
    listLoaded,
    dispatch,
    selectedUserId,
    selectedUserName,
  ]);

  const handleLoadMore = async () => {
    const response = await request({
      method: 'POST',
      url: '/v3/private/service/search',
      data: {
        orderBy: orderBy,
        city: selectedCity,
        experience: selectedExperience,
        userId: selectedUserId,
        name: selectedUserName,
        username: selectedUserName, // temp while partnership is disabled
        priceRange: selectedPriceRange && {
          min: selectedPriceRange.min,
          max: selectedPriceRange.max,
        },
        page: (currentPage ?? 0) + 1,
      },
    });

    if (response) {
      const buildedData = _.map(response.data, (service) => ({
        id: service.id,
        imageUrl: service.imageUrl ?? placeholderImage,
        name: service.name,
        description: service.description,
        price: service?.data?.customPrice ? undefined : service.price,
        city: service.contactData.city,
        createdOn: new Date(service.createdOn),
      }));

      setList([...list, ...buildedData]);
    }
  };

  if (!listLoaded) {
    return (
      <div className={styles.serviceList}>
        <Skeleton height={'100px'} />
        <Skeleton height={'100px'} />
        <Skeleton height={'100px'} />
      </div>
    );
  }

  return (
    <AnimatePresence mode='wait'>
      <div className={styles.serviceList}>
        {_.map(list, (service, index) => (
          <ServiceCard
            key={index}
            id={service.id}
            imageUrl={service.imageUrl ?? placeholderImage}
            title={service.name}
            description={service.description}
            price={service.price}
            city={service.city}
          />
        ))}
        {Boolean(searchResults) && list?.length < Number(searchResults) && (
          <button
            className={styles.servicesShowMoreBtn}
            onClick={handleLoadMore}
          >
            Rodyti daugiau
          </button>
        )}
      </div>
    </AnimatePresence>
  );
};

type ServiceCardProps = {
  id: number;
  imageUrl: string;
  title: string;
  description: string;
  price?: number;
  city: string;
};

const ServiceCard: React.FC<ServiceCardProps> = ({
  id,
  imageUrl,
  title,
  description,
  price,
  city,
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <motion.div
      className={styles.serviceCardContainer}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      initial={{
        opacity: 0.1,
        x: -100,
        scale: 0.4,
      }}
      animate={{
        opacity: 1,
        x: 0,
        scale: 1,
      }}
      transition={{
        duration: 0.7,
      }}
    >
      <div
        className={styles.serviceCardImg}
        style={{
          background: `url(${imageUrl}) lightgray 50% / cover no-repeat`,
        }}
      />
      <div className={styles.serviceCardContainerInner}>
        <p className={styles.serviceCardTitle}>{title}</p>
        <p className={styles.serviceCardDescription}>{description}</p>
        <p className={styles.serviceCardPrice}>
          <span>€{_.isUndefined(price) ? '' : price}</span> /{' '}
          {_.isUndefined(price) ? 'Kaina sutartinė' : 'Val'}
        </p>
        <p className={styles.serviceCardCity}>{city}</p>
      </div>
      {hovering && <HoveringToolBox id={id} />}
    </motion.div>
  );
};

const HoveringToolBox: React.FC<{ id: number }> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { rememberedServiceIds } = useAppSelector((state) => state.serviceList);
  const user = useAppSelector((state) => state.user);

  const isRemembered = useMemo(
    () => _.some(rememberedServiceIds, (sid) => sid === id),
    [id, rememberedServiceIds]
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const handleViewService = () => {
    dispatch(openModal({ selectedServiceId: id }));
  };

  const handleRemmemberService = useCallback(
    async (serviceId: number) => {
      const services = new Set(rememberedServiceIds);

      if (services.has(serviceId)) {
        services.delete(serviceId);

        await request({
          method: 'DELETE',
          url: '/v3/private/remember',
          data: {
            serviceId: serviceId,
          },
        });
      } else {
        services.add(serviceId);

        await request({
          method: 'POST',
          url: '/v3/private/remember',
          data: {
            userId: user.id,
            serviceId: serviceId,
          },
        });
      }

      dispatch(
        setData({
          rememberedServiceIds: Array.from(services),
        })
      );
    },
    [dispatch, rememberedServiceIds, user]
  );

  const handleMessageToServiceOwner = async () => {
    setIsDisabled(true);

    const result = await request(
      {
        method: 'POST',
        url: '/v3/private/chat-room',
        data: {
          serviceId: id,
        },
      },
      dispatch,
      false,
      () => setIsDisabled(false)
    );

    if (result) {
      dispatch(persistChatRoom(result.chatRoom));
      dispatch(setActiveChatRoom(result.chatRoom.id));

      dispatch(
        setCurrentMainPanelSegment({
          segment: MainPanelSegments.MESSAGES_CENTER,
        })
      );
    }

    setIsDisabled(false);
  };

  return (
    <motion.div
      className={styles.hoveringToolboxContainer}
      initial={{
        x: -100,
        opacity: 0.5,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      exit={{
        x: 200,
        opacity: 0,
      }}
      layout
    >
      <IconButton className={styles.toolboxIconBtn} onClick={handleViewService}>
        <Icon>
          <img src={eyeIcon} alt='eye' className={styles.toolboxIcon} />
        </Icon>
      </IconButton>
      <IconButton
        className={styles.toolboxIconBtn}
        onClick={() => handleRemmemberService(id)}
      >
        <Icon>
          <img
            src={heartIcon}
            alt='heart'
            className={styles.toolboxIcon}
            style={
              isRemembered
                ? {
                  filter:
                    'invert(31%) sepia(83%) saturate(7500%) hue-rotate(1deg) brightness(104%) contrast(107%)',
                }
                : {}
            }
          />
        </Icon>
      </IconButton>
      <IconButton
        disabled={isDisabled}
        className={styles.toolboxIconBtn}
        style={{}}
        onClick={handleMessageToServiceOwner}
      >
        <Icon>
          <img src={messageIcon} alt='message' className={styles.toolboxIcon} />
        </Icon>
      </IconButton>
    </motion.div>
  );
};
