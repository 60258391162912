import { useCallback, useMemo, useState } from 'react';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../global/GlobalStore';

import {
    Reservation,
    ReservationsSegments,
    ReservationStatus,
    setData,
    triggerForceReload,
} from '../../../../../global/reducers/ReservationsReducer';

import styles from './styles/ReservationsList.module.scss';

import { Skeleton } from '@mui/material';
import { useAsync } from 'react-use';
import request from '../../../../../global/helpers/utils';

import clockIcon from '../../../../../assets/icons/clock.svg';
import placeIcon from '../../../../../assets/icons/location.svg';
import defaultIcon from '../../../../../assets/icons/avatar-placeholder.svg';
import crossIcon from '../../../../../assets/icons/add.svg';

import _ from 'lodash';
import { displayNotification, setGlobalLoading } from '../../../../../global/reducers/GlobalReducer';

const ReservationsList: React.FC = () => {
    const { activeSegment, isLoading, forceReload } = useAppSelector(
        (state) => state.reservations
    );
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const dispatch = useAppDispatch();

    useAsync(async () => {
        dispatch(setData({ isLoading: true }));
        const response = await request<Reservation[]>(
            {
                url: '/v3/private/reservations',
                method: 'GET',
            },
            dispatch,
            false,
            () => {
                dispatch(setData({ isLoading: false }));
            }
        );

        if (response) {
            // map response to reservations;
            setReservations(response);
        }

        dispatch(setData({ isLoading: false }));
    }, [forceReload]);

    const visits = useMemo(() => {
        if (!isLoading) {
            return reservations.filter((reservation) => {
                switch (activeSegment) {
                    case ReservationsSegments.ALL:
                        return true;
                    case ReservationsSegments.ACCEPTED:
                        return (
                            reservation.status === ReservationStatus.ACCEPTED_RESERVATION ||
                            reservation.status ===
                            ReservationStatus.PENDING_PAYMENT_RESERVATION
                        );
                    case ReservationsSegments.UNKNOWN:
                        return (
                            reservation.status === ReservationStatus.PAYMENT_IN_PROGRESS ||
                            reservation.status === ReservationStatus.PENDING_RESERVATION ||
                            reservation.status ===
                            ReservationStatus.PENDING_PAYMENT_RESERVATION
                        );
                }
            });
        } else {
            return [];
        }
    }, [activeSegment, isLoading, reservations]);

    if (isLoading) {
        return (
            <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <br />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
        );
    }

    return (
        <div className={styles.reservation_grid}>
            {visits.map((visit, index) => (
                <ReservationsItem key={`reservation-${index}`} reservation={visit} />
            ))}
        </div>
    );
};

export default ReservationsList;

type ReservationsItemProps = {
    reservation: Reservation;
};

const ReservationsItem: React.FC<ReservationsItemProps> = ({ reservation }) => {
    const constructedDate = useMemo(() => {
        const date = reservation.date.split('-'); // format: 2022-01-01

        return {
            day: Number(date[2]),
            month: getLithuanianDate(reservation.date),
        };
    }, [reservation.date]);

    const statusBadge = useMemo(() => {
        let label = '',
            styleClass = '';
        switch (reservation.status) {
            case ReservationStatus.ACCEPTED_RESERVATION:
            case ReservationStatus.FINISHED_RESERVATION:
                label = 'Patvirtinta';
                styleClass = styles.badge_accepted;
                break;
            case ReservationStatus.PENDING_RESERVATION:
            case ReservationStatus.PENDING_PAYMENT_RESERVATION:
                label = 'Nepatvirtinta';
                styleClass = styles.badge_pending;
                break;
            case ReservationStatus.REJECTED_RESERVATION:
            case ReservationStatus.CANCELED_RESERVATION:
            case ReservationStatus.INVALID_RESERVATION:
                label = 'Atmesta';
                styleClass = styles.badge_rejected;
                break;
            case ReservationStatus.PAYMENT_IN_PROGRESS:
                label = 'Mokėjimas vyksta';
                styleClass = styles.badge_payment_in_progress;
                break;
            default:
                break;
        }

        return <div className={`${styles.badge} ${styleClass}`}>{label}</div>;
    }, [reservation.status]);

    return (
        <div className={styles.reservation_item}>
            <div className={styles.calendar_box}>
                <p className={styles.calendar_hour}>{constructedDate.day}</p>
                <p className={styles.calendar_date}>{constructedDate.month}</p>
            </div>
            <div className={styles.vertical_spacer} />
            <div className={styles.reservation_box}>
                <div className={styles.reservation_box_container}>
                    <img alt='laikas' src={clockIcon} className={styles.icon_clock} />
                    <div className={styles.reservation_box_text}>
                        <p className={styles.pnb_tem}>
                            {translateHour(reservation.timeFrom)}
                            {reservation.timeTo && ` - ${translateHour(reservation.timeTo)}`}
                        </p>
                        {statusBadge}&nbsp;
                    </div>
                </div>
                <div className={styles.reservation_box_container}>
                    <img alt='vieta' src={placeIcon} className={styles.icon_clock} />
                    <div className={styles.reservation_box_text}>
                        <p>
                            {_.truncate(reservation.serviceData.address, {
                                length: 200,
                                omission: '...',
                            })}
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.reservation_box}>
                <p className={styles.sevice_title}>
                    {_.truncate(reservation.serviceData.name, {
                        length: 255,
                        omission: '...',
                    })}
                </p>
                <div className={styles.service_user_box}>
                    <img
                        src={reservation.user.avatarUrl ?? defaultIcon}
                        alt='avatar'
                        className={styles.service_user_img}
                    />
                    <p className={styles.service_user_text}>
                        Vizitas: <span>{reservation.user.name}</span>
                    </p>
                </div>
            </div>
            <ActionButtons reservation={reservation} />
        </div>
    );
};

function getLithuanianDate(date: string) {
    const dateArray = date.split('-');
    const month = dateArray[1];
    const months = [
        'Sausio',
        'Vasario',
        'Kovo',
        'Balandžio',
        'Gegužės',
        'Birželio',
        'Liepos',
        'Rugpjūčio',
        'Rugsėjo',
        'Spalio',
        'Lapkričio',
        'Gruodžio',
    ];
    return months[parseInt(month) - 1];
}

function translateHour(hour: string) {
    // format: 12.00
    const hourArray = hour.split('.');
    return `${hourArray[0]}:${hourArray[1] ?? 0}0`;
}

const ActionButtons: React.FC<ReservationsItemProps> = ({ reservation }) => {
    const { globalLoading } = useAppSelector((state) => state.global);
    const user = useAppSelector((state) => state.user);

    const dispatch = useAppDispatch();

    const actionTitle = useMemo(() => {
        switch (reservation.status) {
            case ReservationStatus.PENDING_RESERVATION: {
                if (reservation.userOwnService) {
                    return { label: 'Patvirtinti', styleClass: styles.action_btn_accept };
                }
                return { label: 'Keisti laiką', styleClass: '' };
            }
            case ReservationStatus.PENDING_PAYMENT_RESERVATION: {
                if (!reservation.userOwnService) {
                    return {
                        label: 'Apmokėti',
                        styleClass: styles.action_btn_accept,
                    };
                }

                return null;
            }
            case ReservationStatus.ACCEPTED_RESERVATION: {
                // if (reservation.userOwnService) {
                //     return {
                //         label: 'Keisti laiką',
                //         styleClass: '',
                //     };
                // }
                return null;
            }
            case ReservationStatus.PAYMENT_IN_PROGRESS:
            case ReservationStatus.FINISHED_RESERVATION:
            case ReservationStatus.CANCELED_RESERVATION:
            case ReservationStatus.REJECTED_RESERVATION:
            case ReservationStatus.INVALID_RESERVATION:
                return null;
            default:
                break;
        }

        return { label: 'Informacija', styleClass: styles.action_btn_info };
    }, [reservation.status]);

    const showCancelAction = useMemo(() => {
        switch (reservation.status) {
            // for canceling reservation
            case ReservationStatus.PENDING_RESERVATION:
            case ReservationStatus.ACCEPTED_RESERVATION:
            // for removing item from view
            case ReservationStatus.FINISHED_RESERVATION:
            case ReservationStatus.CANCELED_RESERVATION:
            case ReservationStatus.REJECTED_RESERVATION:
            case ReservationStatus.INVALID_RESERVATION:
                return true;
            default:
                return false;
        }
    }, [reservation.status]);

    const handleAction = async () => {
        dispatch(setGlobalLoading(true));
        switch (reservation.status) {
            case ReservationStatus.PENDING_PAYMENT_RESERVATION:
            case ReservationStatus.PENDING_RESERVATION:
            case ReservationStatus.ACCEPTED_RESERVATION: {
                if (reservation.userOwnService && !user.stripeData?.stripeAccountBillable) {
                    // cannot accept reservation if user has no payment method
                    dispatch(displayNotification({
                        severity: 'warning',
                        message: 'Jūs neturite nurodę mokėjimo metodo, todėl negalite priimti rezervacijos',
                    }))

                    return;
                }


                dispatch(
                    setData({
                        isReservationEditingModalOpen: true,
                        editingReservation: reservation,
                    })
                );
                break;
            }
        }
        dispatch(setGlobalLoading(false));
    };

    const handleRemoveAction = useCallback(async () => {
        dispatch(setGlobalLoading(true));
        switch (reservation.status) {
            // for canceling reservation
            case ReservationStatus.PENDING_RESERVATION:
            case ReservationStatus.ACCEPTED_RESERVATION: {
                // if user, cancel reservation -> show modal that reservation fee is not returned

                const response = await request<Reservation[]>(
                    {
                        url: `/v3/private/reservations/${reservation.id}/cancel`,
                        method: 'POST',
                    },
                    dispatch,
                    false,
                    () => {
                        dispatch(setData({ isLoading: false }));
                    }
                );

                if (response) {
                    dispatch(triggerForceReload());
                }
                break;
            }
            // for removing item from view
            case ReservationStatus.FINISHED_RESERVATION:
            case ReservationStatus.CANCELED_RESERVATION:
            case ReservationStatus.REJECTED_RESERVATION:
            case ReservationStatus.INVALID_RESERVATION: {
                const response = await request<Reservation[]>(
                    {
                        url: `/v3/private/reservations/${reservation.id}`,
                        method: 'DELETE',
                    },
                    dispatch,
                    false,
                    () => {
                        dispatch(setData({ isLoading: false }));
                    }
                );

                if (response) {
                    dispatch(triggerForceReload());
                }
                break;
            }
        }

        dispatch(setGlobalLoading(false));
    }, [reservation, dispatch]);

    return (
        <div className={styles.action_btn_box}>
            {actionTitle && (
                <button
                    className={`${styles.action_btn} ${actionTitle.styleClass}`}
                    onClick={handleAction}
                    disabled={globalLoading}
                >
                    {actionTitle.label}
                </button>
            )}
            {showCancelAction && (
                <button
                    className={styles.remove_btn}
                    onClick={handleRemoveAction}
                    disabled={globalLoading}
                >
                    <img src={crossIcon} alt='naikinti' />
                </button>
            )}
        </div>
    );
};
