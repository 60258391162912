import { Menu, MenuItem, Modal } from '@mui/material';
import { BellIcon } from '../../../../../components/Icon/Icon';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../global/GlobalStore';
import {
    handleAdjustCalendarModal,
    setData,
} from '../../../../../global/reducers/ServiceAdjustReducer';

import exitIcon from '../../../../../assets/icons/add.svg';
import likeShapeIcon from '../../../../../assets/icons/vector.svg';
import arrow_down_icon from '../../../../../assets/icons/arrow-down.svg';

import styles from './Calendar.module.scss';
import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useMount } from 'react-use';

export enum TimeChangeType {
    SELECTED_DAYS = 1,
    SELECTED_MONTH,
    SELECTED_YEAR,
}

const generateSequence = (from: number, to: number) => {
    const sequence: number[] = [];
    let current = from;

    while (current <= to) {
        sequence.push(parseFloat(current.toFixed(1)));

        current += 0.3;

        if (current <= to) {
            sequence.push(parseFloat(current.toFixed(1)));
        }

        current = parseFloat((current + 0.7).toFixed(1));
    }

    return sequence;
};

const getHolidays = async (year: number): Promise<string[]> => {
    const response = await fetch(
        `https://date.nager.at/api/v3/publicholidays/${year}/LT`
    ).then((r) => r.json());

    return _.map(response ?? [], (d) => String(d?.date));
};

function getDaysOfMonth(year: number, month: number) {
    const daysOfMonth = [];

    // Adjust for 0-based month indexing in JavaScript (0 = January, 1 = February, ...)
    const date = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0).getDate(); // Last day of the month

    // Loop through all the days of the month
    for (let day = 1; day <= lastDay; day++) {
        date.setDate(day); // Set the current date

        // Get the weekday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        let weekDay = date.getDay() - 1; // Adjust Sunday to 7 instead of 0

        if (weekDay === 0) weekDay = 7;
        if (weekDay === -1) weekDay = 6;

        // Push the result in the format: { date: 'yyyy-mm-dd', weekDay: 1 }
        daysOfMonth.push({
            date: date.toISOString().split('T')[0], // Format as yyyy-mm-dd
            weekDay: weekDay, // Adjust Sunday to 6 instead of -1
        });
    }

    return daysOfMonth;
}

function getRemainingMonths(month: number) {
    const months = [];

    for (let i = month; i < 12; i++) {
        months.push(i);
    }

    return months;
}

const CalendarInfoEditor: React.FC = () => {
    const { adjustCalendarModalOpen, calendarData } = useAppSelector(
        (state) => state.serviceAdjust
    );
    const {
        calendarEditorType,
        calendarEditorData,
        selectedMonth,
        selectedYear,
    } = useAppSelector((state) => state.serviceAdjust);

    const isCalendarSet = useMemo(
        () => calendarData?.length ?? 0 > 0,
        [calendarData]
    );

    const dispatch = useAppDispatch();

    const handleClose = () => dispatch(handleAdjustCalendarModal());

    const handleInit = () => {
        const map: { [date: string]: number[] } = {};
        for (const data of calendarData ?? []) {
            map[data.date] = data.hoursMark;
        }
        return map;
    };

    useMount(() => {
        dispatch(setData({ calendarEditorType: TimeChangeType.SELECTED_DAYS }));
    });

    const handleSave = async () => {
        const calendarData: {
            date: string;
            hoursMark: number[];
        }[] = [];
        // collect all posible calendar data
        // send to reducer
        const month = selectedMonth ?? new Date().getMonth();
        const year = selectedYear ?? new Date().getFullYear();

        switch (calendarEditorType) {
            case TimeChangeType.SELECTED_MONTH: {
                const holidays = calendarEditorData?.nationalDaysExcluded
                    ? await getHolidays(year)
                    : [];

                const days = getDaysOfMonth(year, month + 1);

                for (const day of days) {
                    const [from, to] = calendarEditorData?.dates?.[day.weekDay] ?? [
                        -1, -1,
                    ];

                    if (from === -1 || to === -1) {
                        continue;
                    }

                    if (
                        calendarEditorData?.nationalDaysExcluded &&
                        holidays.includes(day.date)
                    ) {
                        continue;
                    }

                    const sequence = generateSequence(from, to);
                    calendarData.push({
                        date: day.date,
                        hoursMark: sequence,
                    });
                }
                break;
            }
            case TimeChangeType.SELECTED_YEAR: {
                const holidays = calendarEditorData?.nationalDaysExcluded
                    ? await getHolidays(year)
                    : [];
                const months = getRemainingMonths(month);

                for (const m of months) {
                    const days = getDaysOfMonth(year, m + 1);

                    for (const day of days) {
                        const [from, to] = calendarEditorData?.dates?.[day.weekDay] ?? [
                            -1, -1,
                        ];

                        if (from === -1 || to === -1) {
                            continue;
                        }

                        if (
                            calendarEditorData?.nationalDaysExcluded &&
                            holidays.includes(day.date)
                        ) {
                            continue;
                        }

                        const sequence = generateSequence(from, to);
                        calendarData.push({
                            date: day.date,
                            hoursMark: sequence,
                        });
                    }
                }
                break;
            }
            default: {
                for (const [date, hours] of Object.entries(
                    calendarEditorData?.dates ?? {}
                )) {
                    const mark = hours as number[];
                    if (mark?.length && _.some(mark, (i) => i !== -1)) {
                        calendarData.push({
                            date,
                            hoursMark: _.orderBy(hours as number[], (i) => i, [
                                'asc',
                            ]) as number[],
                        });
                    }
                }
                break;
            }
        }

        dispatch(setData({ calendarData }));
        handleClose();
    };

    return (
        <>
            {!isCalendarSet && (
                <div className={styles.warningBox}>
                    <div className={styles.warningBoxIcon}>
                        <BellIcon primary='#CE3A3B' />
                    </div>
                    <p>Prašome pridėti kalendoriuje salono darbo laiką!</p>
                </div>
            )}
            <Modal
                open={Boolean(adjustCalendarModalOpen)}
                onClose={handleClose}
                className={styles.modal}
            >
                <div className={styles.container}>
                    <div className={styles.titleContainer}>
                        <p>Pridėkite salono darbo dienas ir laiką</p>
                        <button onClick={handleClose}>
                            <img src={exitIcon} alt='delete' />
                        </button>
                    </div>
                    <CalendarConfigurator initial={handleInit()} />
                    <button onClick={handleSave} className={styles.saveBtn}>
                        Išsaugoti
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default CalendarInfoEditor;

export const formatDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);

    return date.toLocaleDateString('lt-LT');
}

const CalendarConfigurator: React.FC<{
    initial: { [date: string]: number[] };
}> = ({ initial }) => {
    const [selectedDateMap, setSelectedDateMap] = useState<{
        [date: string]: number[];
    }>(initial);
    const [dates, setDates] = useState<Date[]>([]);
    const [selected, setSelected] = useState<Date>();

    const [selectedType, setSelectedType] = useState<TimeChangeType>(
        TimeChangeType.SELECTED_DAYS
    );

    const dispatch = useAppDispatch();

    const handleDateChange = (newDates: Date[], selectedDate?: Date) => {
        setSelected(selectedDate);

        const affectedDate = _.head(
            _.differenceBy(dates, newDates, (d) => formatDate(d))
        );

        const affectedDateString = affectedDate
            ? formatDate(affectedDate)
            : undefined;

        // check if date has no times selected
        if (affectedDateString && selectedDateMap[affectedDateString]?.length) {
            return false;
        }

        // if true -> deselect date else just change focus
        if (affectedDateString) {
            const map = _.cloneDeep(selectedDateMap);
            delete map[affectedDateString];

            setSelectedDateMap(map);
            dispatch(
                setData({
                    calendarEditorData: {
                        dates: map,
                    },
                })
            );
        }

        setDates(newDates);

        return true;
    };

    const handleTimeSelect = (value: number, isSelected: boolean) => {
        const map = _.cloneDeep(selectedDateMap);

        const formated = selected && formatDate(selected);
        if (value < 0 && formated && isSelected) {
            map[formated] = [];

            setSelectedDateMap(map);
            dispatch(
                setData({
                    calendarEditorData: {
                        dates: map,
                    },
                })
            );
            return;
        }

        if (!isSelected && formated) {
            _.remove(map[formated], (v) => v === value);
        }

        if (isSelected && formated) {
            if (!map[formated]) {
                map[formated] = [];
            }

            map[formated].push(value);
        }

        if (formated) _.remove(map[formated], (v) => v < 0);

        setSelectedDateMap(map);
        dispatch(
            setData({
                calendarEditorData: {
                    dates: map,
                },
            })
        );
    };

    return (
        <>
            <div className={styles.calendarContainer}>
                <div>
                    <CalendarPicker
                        initial={
                            _.map(Object.keys(initial), (k) => new Date(`${k}T00:00:00`)) ?? []
                        }
                        onClick={handleDateChange}
                        disabled={selectedType !== TimeChangeType.SELECTED_DAYS}
                        onMonthChange={(month) =>
                            dispatch(setData({ selectedMonth: month }))
                        }
                        onYearChange={(year) => dispatch(setData({ selectedYear: year }))}
                    />
                    <TimeTypeSelector
                        changeType={(t) => {
                            setSelectedType(t);
                            dispatch(setData({ calendarEditorType: t }));
                        }}
                    />
                </div>
                <TimeSelectorVertical
                    selected={selected}
                    onTimeSelect={handleTimeSelect}
                    selectedTimes={
                        selected ? selectedDateMap[formatDate(selected)] : undefined
                    }
                    disabled={selectedType !== TimeChangeType.SELECTED_DAYS}
                />
            </div>
            {selectedType !== TimeChangeType.SELECTED_DAYS && <BulkTimeSelector />}
        </>
    );
};

const TimeTypeSelector: React.FC<{
    changeType: (type: TimeChangeType) => void;
}> = ({ changeType }) => {
    const options = useMemo(
        () => [
            {
                label: 'Taikyti pasirinktoms dienoms',
                value: TimeChangeType.SELECTED_DAYS,
            },
            {
                label: 'Taikyti pasirinktam mėnesiui',
                value: TimeChangeType.SELECTED_MONTH,
            },
            {
                label: 'Taikyti iki metų pabaigos',
                value: TimeChangeType.SELECTED_YEAR,
            },
        ],
        []
    );

    return (
        <div className={styles.selectorC}>
            <select
                onChange={(e) =>
                    changeType(Number(e.currentTarget.value) as TimeChangeType)
                }
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <img src={arrow_down_icon} alt='arrow' />
        </div>
    );
};

const monthsInGenitive = [
    'Sausio',
    'Vasario',
    'Kovo',
    'Balandžio',
    'Gegužės',
    'Birželio',
    'Liepos',
    'Rugpjūčio',
    'Rugsėjo',
    'Spalio',
    'Lapkričio',
    'Gruodžio',
];

const monthsInNominative = [
    'Sausis',
    'Vasaris',
    'Kovas',
    'Balandis',
    'Gegužė',
    'Birželis',
    'Liepa',
    'Rugpjūtis',
    'Rugsėjis',
    'Spalis',
    'Lapkritis',
    'Gruodis',
];

function formatLithuanianDate(date: Date) {
    const weekdayFormatter = new Intl.DateTimeFormat('lt-LT', {
        weekday: 'long',
    });
    const dayFormatter = new Intl.DateTimeFormat('lt-LT', { day: 'numeric' });
    const yearFormatter = new Intl.DateTimeFormat('lt-LT', { year: 'numeric' });

    const weekday =
        weekdayFormatter.format(date).charAt(0).toUpperCase() +
        weekdayFormatter.format(date).slice(1);
    const month = monthsInGenitive[date.getMonth()];
    const day = dayFormatter.format(date);
    const year = yearFormatter.format(date);

    return `${weekday}, ${month} ${day}, ${year}`;
}

const CheckMark: React.FC = () => {
    return (
        <div className={styles.vectorBox}>
            <img src={likeShapeIcon} alt={'selected'} />
        </div>
    );
};

const TimeSelectorVertical: React.FC<{
    selected?: Date;
    onTimeSelect: (value: number, isSelected: boolean) => void;
    selectedTimes?: number[];
    disabled?: boolean;
}> = ({ selected, onTimeSelect, selectedTimes, disabled }) => {
    const times = new Set<number>(selectedTimes);

    if (!selected) return <div></div>;

    return (
        <div
            onClick={(e) => {
                if (disabled) {
                    e.stopPropagation();
                    return;
                }
            }}
            className={`${styles.selectorContainer} ${disabled ? styles.selectorContainer_disabled : ''
                }`}
        >
            <div className={styles.timeSelectorTitle}>
                {formatLithuanianDate(selected)}
            </div>
            <div className={styles.timeSelectorContainer}>
                {timeOptions.map((option, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                times.has(option.value)
                                    ? `${styles.selectorTime} ${styles.selectorTime_active}`
                                    : styles.selectorTime
                            }
                            onClick={() =>
                                !disabled &&
                                onTimeSelect(option.value, !times.has(option.value))
                            }
                        >
                            <p>{option.label}</p>
                            {times.has(option.value) && (
                                <div className={styles.mark}>
                                    <CheckMark />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const timeOptions = [
    { label: 'Nedirbame', value: -1 },
    { label: '9:00', value: 9.0 },
    { label: '9:30', value: 9.3 },
    { label: '10:00', value: 10.0 },
    { label: '10:30', value: 10.3 },
    { label: '11:00', value: 11.0 },
    { label: '11:30', value: 11.3 },
    { label: '12:00', value: 12.0 },
    { label: '12:30', value: 12.3 },
    { label: '13:00', value: 13.0 },
    { label: '13:30', value: 13.3 },
    { label: '14:00', value: 14.0 },
    { label: '14:30', value: 14.3 },
    { label: '15:00', value: 15.0 },
    { label: '15:30', value: 15.3 },
    { label: '16:00', value: 16.0 },
    { label: '16:30', value: 16.3 },
    { label: '17:00', value: 17.0 },
    { label: '17:30', value: 17.3 },
    { label: '18:00', value: 18.0 },
    { label: '18:30', value: 18.3 },
    { label: '19:00', value: 19.0 },
    { label: '19:30', value: 19.3 },
    { label: '20:00', value: 20.0 },
    { label: '20:30', value: 20.3 },
    { label: '21:00', value: 21.0 },
    { label: '21:30', value: 21.3 },
    { label: '22:00', value: 22.0 },
    { label: '22:30', value: 22.3 },
    { label: '23:00', value: 23.0 },
    { label: '23:30', value: 23.3 },
    { label: '00:00', value: 0.0 },
];

const dayOptions = [
    {
        label: 'Pr',
        value: 1,
    },
    {
        label: 'An',
        value: 2,
    },
    {
        label: 'Tr',
        value: 3,
    },
    {
        label: 'Kt',
        value: 4,
    },
    {
        label: 'Pn',
        value: 5,
    },
    {
        label: 'Št',
        value: 6,
    },
    {
        label: 'Se',
        value: 7,
    },
];

const BulkTimeSelector: React.FC = () => {
    const [nationalDaysExcluded, setNationalDaysExcluded] =
        useState<boolean>(true);

    const [dayRange, setDayRange] = useState<{ [day: number]: [number, number] }>(
        {}
    );
    const dispatch = useAppDispatch();

    const handleTimeRangeChange = (
        day: number,
        rangeFrom?: number,
        rangeTo?: number
    ) => {
        // set map for the week day time range here
        const current = _.cloneDeep(dayRange);

        const range = current[day] || [-1, -1];

        range[0] = rangeFrom !== undefined ? rangeFrom : range[0];
        range[1] = rangeTo !== undefined ? rangeTo : range[1];

        current[day] = range;

        setDayRange(current);

        dispatch(
            setData({
                calendarEditorData: {
                    dates: current,
                    nationalDaysExcluded: nationalDaysExcluded,
                },
            })
        );
    };

    return (
        <div className={styles.bulkContainer}>
            <div
                className={styles.bulkTitle}
                onClick={() => {
                    setNationalDaysExcluded(!nationalDaysExcluded);
                    dispatch(
                        setData({
                            calendarEditorData: {
                                dates: dayRange,
                                nationalDaysExcluded: !nationalDaysExcluded,
                            },
                        })
                    );
                }}
            >
                <div className={styles.customMark}>
                    {nationalDaysExcluded && <img src={likeShapeIcon} alt={'selected'} />}
                </div>
                <p>Valstybinių švenčių dienomis nedirbame</p>
            </div>
            <div className={styles.custom_selector_box}>
                {dayOptions.map((option, index) => (
                    <div key={index} className={styles.selector_container}>
                        <div className={styles.selector_title}>{option.label}</div>
                        <TimeRangeSelector
                            label={'Nuo'}
                            handleTimeChange={(selectedTime) =>
                                handleTimeRangeChange(option.value, selectedTime, undefined)
                            }
                            hasError={
                                dayRange[option.value]?.[0] > dayRange[option.value]?.[1]
                            }
                        />
                        <TimeRangeSelector
                            label={'Iki'}
                            handleTimeChange={(selectedTime) =>
                                handleTimeRangeChange(option.value, undefined, selectedTime)
                            }
                            hasError={
                                dayRange[option.value]?.[0] > dayRange[option.value]?.[1]
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const TimeRangeSelector: React.FC<{
    handleTimeChange?: (selectedTime: number) => void;
    label: string;
    hasError?: boolean;
}> = ({ handleTimeChange, label, hasError }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedTime, setSelectedTime] = useState<number>();
    const timeMap = useMemo(() => _.keyBy(timeOptions, 'value'), []);
    const handleClose = () => setAnchorEl(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleSelectionOption = (
        e: React.MouseEvent<HTMLElement>,
        option: number
    ) => {
        setSelectedTime(option);
        handleTimeChange && handleTimeChange(option);
        handleClose();

        e.stopPropagation();
    };

    return (
        <div className={styles.time_range_title_wrapper}>
            <div
                className={`${styles.time_range_box} ${hasError ? styles.time_range_box__error : ''
                    }`}
                onClick={handleClick}
            >
                <div className={styles.time_range_title}>
                    <p>{label}</p> <img src={arrow_down_icon} alt='arrow' />
                </div>
                {selectedTime !== undefined && selectedTime !== -1 && (
                    <div className={styles.time_range_text}>
                        {timeMap[selectedTime].label}
                    </div>
                )}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            borderRadius: '30px',
                        },
                    }}
                >
                    <div className={styles.timeRangeBox}>
                        <div className={styles.timeRangeHeadline}>
                            <p>Pasirinkite laiką</p>
                            <button onClick={handleClose}>
                                <img src={exitIcon} alt='delete' />
                            </button>
                        </div>
                        <div className={styles.spacer} />
                        <div className={styles.timeRangeList}>
                            {timeOptions.map((option, index) => (
                                <MenuItem
                                    className={styles.timeRangeListItem}
                                    key={index}
                                    onClick={(e) => handleSelectionOption(e, option.value)}
                                >
                                    <span className={styles.timeRangeListItemText}>
                                        {option.label}
                                    </span>
                                </MenuItem>
                            ))}
                        </div>
                    </div>
                </Menu>
            </div>
        </div>
    );
};

type SelectorContainerProps = {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    handleSelectionOption: (e: React.MouseEvent, time: number) => void;
    availableTimes: number[]
}

export const SelectorContainer: React.FC<SelectorContainerProps> = ({
    anchorEl,
    handleClose,
    handleSelectionOption,
    availableTimes
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: '30px',
                },
            }}
        >
            <div className={styles.timeRangeBox}>
                <div className={styles.timeRangeHeadline}>
                    <p>Pasirinkite laiką</p>
                    <button onClick={handleClose}>
                        <img src={exitIcon} alt='delete' />
                    </button>
                </div>
                <div className={styles.spacer} />
                <div className={styles.timeRangeList}>
                    {timeOptions.filter(t => availableTimes.includes(t.value)).map((option, index) => (
                        <MenuItem
                            className={styles.timeRangeListItem}
                            key={index}
                            onClick={(e) => handleSelectionOption(e, option.value)}
                        >
                            <span className={styles.timeRangeListItemText}>
                                {option.label}
                            </span>
                        </MenuItem>
                    ))}
                </div>
            </div>
        </Menu>
    )
}


export const CalendarPicker: React.FC<{
    initial?: Date[];
    availableDates?: Date[];
    onClick?: (selectedDates: Date[], selectedDate?: Date) => boolean;
    onMonthChange?: (month: number) => void;
    onYearChange?: (year: number) => void;
    disabled?: boolean;
    reserveMode?: boolean;
}> = ({ onClick, onMonthChange, onYearChange, disabled, initial, availableDates, reserveMode }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [selectedDates, setSelectedDates] = useState<Date[]>(initial ?? []);
    const [selectedDay, setSelectedDay] = useState<Date>();

    const daysOfWeek = ['S', 'Pr', 'An', 'Tr', 'Kt', 'Pn', 'Š'];

    function getDaysInMonth(month: number, year: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    function getFirstDayOfMonth(month: number, year: number): number {
        return new Date(year, month, 1).getDay();
    }

    const handleDayClick = (day: number) => {
        if (disabled) return;

        let dates = _.cloneDeep(selectedDates);
        const selected = new Date(currentYear, currentMonth, day);
        const currentActionDate = _.isEqual(selected, selectedDay)
            ? undefined
            : selected;


        if (reserveMode && availableDates) {
            // no selecting invalid dates
            if (!_.some(availableDates, (d) => _.isEqual(d, selected))) {
                return;
            }
            dates = [selected]
        }

        setSelectedDay(currentActionDate);

        if (
            _.some(dates, (d) => _.isEqual(d, selected)) &&
            selectedDay !== undefined
        ) {
            _.remove(dates, (d) => _.isEqual(d, selected));
        } else {
            dates.push(selected);
        }



        if (onClick && !onClick(dates, currentActionDate)) {
            // disable selection/deselection
            return;
        }

        setSelectedDates(dates);
    };

    const handlePreviousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
            onMonthChange && onMonthChange(11);
            onYearChange && onYearChange(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
            onMonthChange && onMonthChange(currentMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
            onMonthChange && onMonthChange(0);
            onYearChange && onYearChange(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
            onMonthChange && onMonthChange(currentMonth + 1);
        }
    };

    const days = useMemo(() => {
        const daysInMonth = getDaysInMonth(currentMonth, currentYear);
        const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);

        const d = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            d.push(null); // Empty slots for days before the month starts
        }
        for (let day = 1; day <= daysInMonth; day++) {
            d.push(day);
        }

        return d;
    }, [currentMonth, currentYear]);

    const isEqualDates = useCallback(
        (date?: Date, dayToCompare?: number | null) => {
            if (!date) return false;
            if (!dayToCompare && dayToCompare !== 0) return false;

            return (
                date.getFullYear() === currentYear &&
                date.getMonth() === currentMonth &&
                date.getDate() === dayToCompare
            );
        },
        [currentMonth, currentYear]
    );

    const isDisabledDate = useCallback((day: number | null) => {
        if (disabled) return true;

        if (reserveMode) {
            // check if date is in past 
            const currentDate = new Date();
            const date = new Date(currentYear, currentMonth, day ?? 1);
            if (date < currentDate) {
                return true;
            }
        }

        if (availableDates) {
            const date = new Date(currentYear, currentMonth, day ?? 1);
            return !_.some(availableDates, (d) => _.isEqual(d, date));
        }

        return false;
    }, [currentYear, currentMonth, disabled, availableDates]);

    return (
        <>
            <div className={styles.calendar}>
                <div className={styles.calendarHeader}>
                    <button onClick={handlePreviousMonth}>
                        <img
                            className={styles.arrow_left}
                            src={arrow_down_icon}
                            alt='arrow'
                        />
                    </button>
                    <p>
                        {monthsInNominative[currentMonth]}, {currentYear}
                    </p>
                    <button onClick={handleNextMonth}>
                        <img
                            className={styles.arrow_right}
                            src={arrow_down_icon}
                            alt='arrow'
                        />
                    </button>
                </div>
                <div className={styles['days-of-week']}>
                    {daysOfWeek.map((day, index) => (
                        <div key={index} className={styles['day-of-week']}>
                            {day}
                        </div>
                    ))}
                </div>
                <div
                    className={`${styles.days} ${disabled ? styles.days_disabled : ''}`}
                >
                    {days.map((day, index) => {
                        const disabledBtn = isDisabledDate(day);
                        return (
                            <button
                                key={index}
                                className={`${styles.day} ${isEqualDates(selectedDay, day)
                                    ? styles['day-current']
                                    : _.some(selectedDates, (d) => isEqualDates(d, day))
                                        ? styles['day-selected']
                                        : ''
                                    } ${disabledBtn ? styles.day_disabled : ''}`}
                                onClick={() => (day !== null && !disabledBtn) && handleDayClick(day)}
                                disabled={!day}
                            >
                                {day}
                            </button>
                        )
                    })}
                </div>
            </div>
        </>
    );
};
