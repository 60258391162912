import { Menu, MenuItem, Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';

import styles from './styles/ReserveVisitModal.module.scss';
import { setData } from '../../../../global/reducers/ServiceModalReducer';
import { useMemo, useRef, useState } from 'react';

import exitIcon from '../../../../assets/icons/add.svg';
import clockIcon from '../../../../assets/icons/clock.svg';
import uploadImage from '../../../../assets/icons/Image_plus_1.svg';
import calendarIcon from '../../../../assets/icons/calendar-tick.svg';
import arrowDownIcon from '../../../../assets/icons/arrow-down.svg';

import {
    CalendarPicker,
    formatDate,
    SelectorContainer,
} from '../service/Calendar/CalendarInfoEditor';
import _ from 'lodash';
import { FilePreview } from '../../../../global/reducers/ServiceAdjustReducer';
import { ADDITIONAL_SERVICES, AdditionalServicesText } from '@web-app/common';
import request from '../../../../global/helpers/utils';
import { displayNotification, setGlobalLoading } from '../../../../global/reducers/GlobalReducer';

enum ReverseVisitModalPhase {
    SELECT_VISIT_DATE = 'Rezervuoti vizitą',
    SELECT_ADDITIONAL_SERVICES = 'Papildoma informacija',
}

const ReserveVisitModal: React.FC = () => {
    const {
        selectedServiceId,
        isReserveWindowOpen,
        reservedService,
        extraInfo,
        filesToUpload,
        reservedDate,
        reservedHour,
    } = useAppSelector((state) => state.serviceModal);

    const [phase, setPhase] = useState<ReverseVisitModalPhase>(
        ReverseVisitModalPhase.SELECT_VISIT_DATE
    );

    const dispatch = useAppDispatch();

    const handleClose = () => {
        // Reset data
        setPhase(ReverseVisitModalPhase.SELECT_VISIT_DATE);
        dispatch(
            setData({
                isReserveWindowOpen: false,
                reservedDate: undefined,
                reservedHour: undefined,
            })
        );
    };

    const handleTryReserve = async () => {
        // Send data to server
        const fileData = new FormData();

        fileData.append('serviceId', String(selectedServiceId));
        fileData.append('reservedDate', reservedDate ?? '');
        fileData.append('reservedHour', String(reservedHour ?? -1));
        fileData.append('additionalInformation', extraInfo ?? '');
        fileData.append('additionalService', String(reservedService ?? ''));

        for (const file of filesToUpload ?? []) {
            fileData.append('files', file.object);
        }

        const response = await request({
            method: 'POST',
            url: '/v3/private/service/reserve',
            data: fileData,
        }, dispatch, true, () => dispatch(setGlobalLoading(false)));

        if (response) {
            dispatch(
                displayNotification({
                    severity: 'success',
                    message: 'Vizitas sėkmingai rezervuotas, Laukite meistro patvirtinimo!',
                })
            );
            handleClose();
        }

    };

    const handleReserveAction = async () => {
        switch (phase) {
            case ReverseVisitModalPhase.SELECT_VISIT_DATE:
                // validate time
                if (!reservedDate || !reservedHour) {
                    dispatch(
                        displayNotification({
                            severity: 'error',
                            message: 'Pasirinkite laiką ir datą',
                        })
                    );
                    return;
                }

                // validate if time is not in the past
                const selectedDate = new Date(reservedDate);
                const currentDate = new Date();
                const selectedHour = reservedHour ?? 0;
                const currentHour = currentDate.getHours();
                if (selectedDate < currentDate || (selectedDate.getTime() === currentDate.getTime() && selectedHour < currentHour)) {
                    dispatch(
                        displayNotification({
                            severity: 'error',
                            message: 'Pasirinktas laikas yra praeityje',
                        })
                    );
                    return;
                }


                setPhase(ReverseVisitModalPhase.SELECT_ADDITIONAL_SERVICES);
                break;
            case ReverseVisitModalPhase.SELECT_ADDITIONAL_SERVICES:
                await handleTryReserve();
                break;
        }
    };

    const isDisabled = useMemo(() => {
        if (phase === ReverseVisitModalPhase.SELECT_VISIT_DATE) {
            return !Boolean(reservedDate) || !Boolean(reservedHour);
        }
        if (phase === ReverseVisitModalPhase.SELECT_ADDITIONAL_SERVICES) {
            if (_.isEmpty(extraInfo)) return true;
            if (!filesToUpload?.length) return true;
        }

        return false;
    }, [phase, reservedDate, reservedHour, extraInfo, filesToUpload]);

    const btnText = useMemo(() => {
        if (phase === ReverseVisitModalPhase.SELECT_VISIT_DATE) {
            return 'Toliau';
        }
        if (phase === ReverseVisitModalPhase.SELECT_ADDITIONAL_SERVICES) {
            return 'Rezervuoti';
        }
    }, [phase]);

    return (
        <Modal open={Boolean(isReserveWindowOpen)} onClose={handleClose}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <div className={styles.emptyElement} />
                    <p>{phase}</p>
                    <button onClick={handleClose}>
                        <img src={exitIcon} alt='delete' />
                    </button>
                </div>
                <div className={styles.spacer} />
                {phase === ReverseVisitModalPhase.SELECT_VISIT_DATE && (
                    <ReserveVisitPicker />
                )}
                {phase === ReverseVisitModalPhase.SELECT_ADDITIONAL_SERVICES && (
                    <ReserveVisitAdditionalInformation />
                )}
                <button
                    disabled={isDisabled}
                    onClick={handleReserveAction}
                    className={styles.btn}
                >
                    {btnText}
                </button>
            </div>
        </Modal>
    );
};

export default ReserveVisitModal;

const formatToLocaleString = (isoString?: string) => {
    if (!isoString) {
        return <>&nbsp;</>;
    }
    const date = new Date(isoString ?? '');

    const formatter = new Intl.DateTimeFormat('lt-LT', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const formattedDate = formatter.format(date);
    const parts = formattedDate.split(' ');
    return `${parts[2].charAt(0).toUpperCase() + parts[2].slice(1)} ${parts[3]
        }, ${parts[0]}`;
};

export const formatToLocaleTimeString = (time?: number) => {
    if (!time) {
        return <>&nbsp;</>;
    }

    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 10);

    return `${hours}:${minutes.toString().padStart(0, '0')}0`;
};

const ReserveVisitPicker: React.FC = () => {
    const { calendarData, occupiedCalendarData } = useAppSelector(
        (state) => state.serviceModal
    );
    const { reservedDate, reservedHour } = useAppSelector(
        (state) => state.serviceModal
    );

    const [calendarAnchorEl, setCalendarAnchorEl] = useState<null | HTMLElement>(
        null
    );
    const [timeSelectAnchorEl, setTimeSelectAnchorEl] =
        useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch();

    const initialAvailableCalendarData = useMemo(() => {
        const availableDates: Date[] = [];

        for (const data of calendarData ?? []) {
            if (_.some(occupiedCalendarData ?? [], (o) => o.date === data.date)) {
                continue;
            }

            availableDates.push(new Date(`${data.date}T00:00:00`));
        }

        return availableDates;
    }, [calendarData, occupiedCalendarData]);

    const timesOptions = useMemo(() => {
        const day = calendarData?.find((o) => o.date === reservedDate);
        const occupiedDay = occupiedCalendarData?.find(
            (o) => o.date === reservedDate
        );

        if (!day) {
            return [];
        }

        for (const time of occupiedDay?.hoursMark ?? []) {
            _.remove(day.hoursMark, (o) => o === time);
        }
        return day.hoursMark;
    }, [calendarData, occupiedCalendarData, reservedDate]);

    const handleOpenCalendar = (e: React.MouseEvent<HTMLElement>) => {
        setCalendarAnchorEl(e.currentTarget);
    };
    const handleOpenTimeSelect = (e: React.MouseEvent<HTMLElement>) => {
        if (!reservedDate) {
            return;
        }

        setTimeSelectAnchorEl(e.currentTarget);
    };

    const handleDateChange = (_: Date[], current?: Date) => {
        dispatch(
            setData({ reservedDate: current ? formatDate(current) : undefined })
        );
        return true;
    };

    const handleTimeChange = (_: React.MouseEvent, selectedTime: number) => {
        dispatch(setData({ reservedHour: selectedTime }));
    };

    return (
        <>
            <div className={styles.box}>
                <p className={styles.headline}>
                    Pasirinkite dieną ir laiką, kada norite, kad rezervuotume jūsų vietą.
                </p>
                <div className={styles.input_selector} onClick={handleOpenCalendar}>
                    <img alt='calendar' src={calendarIcon} />
                    <div className={styles.input_selector_box}>
                        <p className={styles.input_selector_box_headline}>Data</p>
                        <p className={styles.input_selector_box_text}>
                            {formatToLocaleString(reservedDate)}
                        </p>
                    </div>
                </div>
                <div
                    className={`${styles.input_selector} ${!Boolean(reservedDate) ? styles.input_selector_disabled : ''
                        }`}
                    onClick={handleOpenTimeSelect}
                >
                    <img alt='clock' src={clockIcon} className={styles.invertIcon} />
                    <div className={styles.input_selector_box}>
                        <p className={styles.input_selector_box_headline}>Laikas</p>
                        <p className={styles.input_selector_box_text}>
                            {formatToLocaleTimeString(reservedHour)}
                        </p>
                    </div>
                </div>
            </div>
            <Menu
                anchorEl={calendarAnchorEl}
                open={Boolean(calendarAnchorEl)}
                onClose={() => setCalendarAnchorEl(null)}
            >
                <CalendarPicker
                    reserveMode
                    availableDates={initialAvailableCalendarData}
                    onClick={handleDateChange}
                />
            </Menu>
            <SelectorContainer
                availableTimes={timesOptions}
                anchorEl={timeSelectAnchorEl}
                handleClose={() => setTimeSelectAnchorEl(null)}
                handleSelectionOption={handleTimeChange}
            />
        </>
    );
};

const ReserveVisitAdditionalInformation: React.FC = () => {
    const dispatch = useAppDispatch();

    return (
        <div>
            <p className={styles.headline}>
                Prašome nurodyti kuo daugiau informacijos, meistrui, kad būtu galima
                nustatyti tikslią paslaugos kainą!
            </p>
            <div><ServiceSelector /></div>
            <div className={styles.extraText__input_box}>
                <p className={styles.extraText_input_headline}>
                    Papildoma informacija <span>(būtina)</span>
                </p>
                <textarea className={styles.extraText_input_headline_textarea} onChange={(e) => dispatch(setData({ extraInfo: e.currentTarget.value }))} />
            </div>
            <FileSelector />
        </div>
    );
};

type FileSelectorProps = {};

const FileSelector: React.FC<FileSelectorProps> = ({ }) => {
    const [filePreviews, setFilePreviews] = useState<FilePreview[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    // const [isDragging, setIsDragging] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
            const previews: FilePreview[] = Array.from(files).map((file) => ({
                url: URL.createObjectURL(file),
                type: file.type,
                object: file,
            }));

            setFilePreviews(previews);

            dispatch(
                setData({
                    filesToUpload: previews,
                })
            );
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // drag n drop

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // setIsDragging(true);
    };

    const handleDragLeave = () => {
        // setIsDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // setIsDragging(false);
        const files = event.dataTransfer.files;

        if (files) {
            const previews: FilePreview[] = Array.from(files).map((file) => ({
                url: URL.createObjectURL(file),
                type: file.type,
                object: file,
            }));
            const prevs = [...(filePreviews || []), ...previews]
            setFilePreviews(prevs.slice(-4));
        }
    };

    return (
        <div
            className={styles.extraText__input_box}
            onClick={handleButtonClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <p className={styles.extraText_input_headline}>
                Nuotraukos arba eskizai <span>(būtina)</span>
            </p>
            {filePreviews?.length > 0 ? (
                <div className={styles.extraText__image_uploaded_box}>
                    {filePreviews.map((file, index) => (
                        <div
                            key={index}
                            className={styles.extraText__image_uploaded}
                        ><img src={file.url} alt='source' /> </div>
                    ))}
                </div>
            ) : (
                <div className={styles.extraText__image_upload}>
                    <img src={uploadImage} alt='upload' />
                </div>
            )}
            <input
                ref={fileInputRef}
                type='file'
                accept='image/*,video/*'
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
        </div>
    );
};

const ServiceSelector: React.FC = () => {
    const { services } = useAppSelector((state) => state.serviceModal);

    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedService, setSelectedService] = useState<ADDITIONAL_SERVICES | null>(null);

    const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        if (services?.length ?? 0 > 0) {
            setAnchorEl(e.currentTarget);
        }
    }

    const handleSelect = (value: ADDITIONAL_SERVICES | string) => {
        setSelectedService(value as ADDITIONAL_SERVICES);
        dispatch(setData({ reservedService: value as ADDITIONAL_SERVICES }));
        setAnchorEl(null);
    }

    return (
        <>
            <div onClick={handleOpen} className={styles.input_selector_service}>
                <p>{selectedService ? AdditionalServicesText[selectedService] : 'Pasirinkite paslaugą'}</p><img src={arrowDownIcon} alt='arrow-down' />
            </div>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                {(services ?? []).map((service, index) => (
                    <MenuItem key={index} onClick={() => handleSelect(service)}>
                        {service ? AdditionalServicesText[service as ADDITIONAL_SERVICES] : ''}
                    </MenuItem>))}
            </Menu>
        </>
    )
}