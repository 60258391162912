import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import ServiceOverview from './ServiceOverview';

import styles from './styles/ServiceAdjustPanel.module.scss';

import dollarCircleIcon from '../../../../assets/icons/dollar-circle.svg';

import ToggleInput from '../../../../components/Toggle/ToggleInput';
import React, { useCallback, useMemo, useRef } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ADDITIONAL_SERVICES, AdditionalServicesText } from '@web-app/common';
import { Checkbox } from '@mui/material';
import _ from 'lodash';
import {
  FilePreview,
  handleAdjustCalendarModal,
  resetData,
  setData,
} from '../../../../global/reducers/ServiceAdjustReducer';

import uploadFileIcon from '../../../../assets/icons/document-upload.svg';
import request from '../../../../global/helpers/utils';
import {
  displayNotification,
  setCurrentMainPanelSegment,
  setGlobalLoading,
} from '../../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../../global/helpers/constants';
import { useMount } from 'react-use';

import CalendarInfoEditor from './Calendar/CalendarInfoEditor';

type ServiceAdjustPanelProps = {
  serviceId?: number; // when editing existing service only
};

const ServiceAdjustPanel: React.FC<ServiceAdjustPanelProps> = () => {
  const { serviceId } = useAppSelector((state) => state.serviceAdjust);
  const dispatch = useAppDispatch();

  useMount(async () => {
    dispatch(setGlobalLoading(true));

    if (serviceId) {
      const response = await request(
        {
          method: 'GET',
          url: '/v3/private/service',
          params: {
            serviceId: serviceId,
          },
        },
        dispatch
      );

      if (response) {
        // set initial edit data
        dispatch(
          setData({
            name: response.service.name,
            description: response.service.description,
            phone: response.service.contactData?.phone,
            email: response.service.contactData?.email,
            city: response.service.contactData?.city,
            address: response.service.contactData?.address,
            price: response.service.price / 100,
            customPrice: response?.service.data?.customPrice,
            selectedService: response.service.data.selectedServices,
            calendarData: response?.service?.data?.calendarData,
            filePreviews: undefined,

            initialData: {
              name: response.service.name,
              description: response.service.description,
              phone: response.service.contactData?.phone,
              email: response.service.contactData?.email,
              city: response.service.contactData?.city,
              address: response.service.contactData?.address,
              price: response.service.price / 100,
              customPrice: response.service.data.customPrice,
              selectedService: response.service.data.selectedServices,
              calendarData: response?.service?.data?.calendarData,
              filePreviews: undefined,
            },
          })
        );
      }
    } else {
      const response = await request({
        method: 'GET',
        url: '/v3/private/user/data',
      });

      dispatch(
        setData({
          initialData: {
            name: '',
            description: '',
            phone: response.contactData?.phone,
            email: response.email,
            city: response.contactData?.city,
            address: response.contactData?.address,
            price: 0,
            customPrice: false,
            selectedService:
              response?.services?.map((s: string) => Number(s)) ?? [],
            filePreviews: [],
            calendarData: [],
          },
        })
      );

      dispatch(resetData());
    }

    dispatch(setGlobalLoading(false));
  });

  return (
    <div className={styles.container}>
      <ServiceEditor />
      <ServiceOverview />
    </div>
  );
};

export default ServiceAdjustPanel;

type ServiceEditorProps = {};

const ServiceEditor: React.FC<ServiceEditorProps> = () => {
  return (
    <div className={styles.editorContainer}>
      <ServiceMainInfoEditor />
      <ContactInfoEditor />
      <MediaInfoEditor />
      <PriceInfoEditor />
      <ExtraServicesInfoEditor />
      <CalendarInfoEditor />
      <ButtonsGrid />
    </div>
  );
};

const ServiceMainInfoEditor: React.FC = () => {
  const { name, description } = useAppSelector((state) => state.serviceAdjust);
  const dispatch = useAppDispatch();

  const nameHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setData({ name: e.target.value }));
    },
    [dispatch]
  );
  const desciptionHandler = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(setData({ description: e.target.value }));
    },
    [dispatch]
  );

  return (
    <div className={styles.infoContainer}>
      <h1>Pavadinimas ir aprašymas</h1>
      <div className={styles.divider} />
      <p className={styles.inputLabel}>Skelbimo pavadinimas</p>
      <input className={styles.nameInput} onChange={nameHandler} value={name} />
      <p className={styles.inputLabel}>Aprašymas</p>
      <textarea
        className={styles.areaInput}
        onChange={desciptionHandler}
        value={description}
      />
    </div>
  );
};

const ContactInfoEditor: React.FC = () => {
  const { phone, email, city, address } = useAppSelector(
    (state) => state.serviceAdjust
  );
  const dispatch = useAppDispatch();

  const phoneHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = /^[0-9,+]*$/;
      if (regex.test(e.target.value) || e.target.value === '') {
        dispatch(setData({ phone: e.target.value }));
      }
    },
    [dispatch]
  );

  const emailHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setData({ email: e.target.value }));
    },
    [dispatch]
  );

  const cityHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setData({ city: e.target.value }));
    },
    [dispatch]
  );

  const addressHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setData({ address: e.target.value }));
    },
    [dispatch]
  );

  return (
    <div className={styles.infoContainer}>
      <h1>Kontaktai</h1>
      <div className={styles.divider} />
      <div className={styles.flexContainer}>
        <div>
          <p className={styles.inputLabelSimple}>Telefono numeris</p>
          <input
            className={styles.simpleInput}
            onChange={phoneHandler}
            value={phone}
          />
        </div>
        <div>
          <p className={styles.inputLabelSimple}>Elektroninis paštas</p>
          <input
            className={styles.simpleInput}
            onChange={emailHandler}
            value={email}
          />
        </div>
      </div>
      <div className={`${styles.flexContainer} ${styles.flexContainer_last}`}>
        <div>
          <p className={styles.inputLabelSimple}>Miestas</p>
          <input
            className={styles.simpleInput}
            onChange={cityHandler}
            value={city}
          />
        </div>
        <div>
          <p className={styles.inputLabelSimple}>Adresas</p>
          <input
            className={styles.simpleInput}
            onChange={addressHandler}
            value={address}
          />
        </div>
      </div>
    </div>
  );
};

const MediaInfoEditor: React.FC = () => {
  const { filePreviews } = useAppSelector((state) => state.serviceAdjust);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  // const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const previews: FilePreview[] = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        type: file.type,
        object: file,
      }));

      dispatch(setData({ filePreviews: previews }));
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // drag n drop

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    // setIsDragging(true);
  };

  const handleDragLeave = () => {
    // setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    // setIsDragging(false);
    const files = event.dataTransfer.files;

    if (files) {
      const previews: FilePreview[] = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        type: file.type,
        object: file,
      }));

      dispatch(
        setData({ filePreviews: [...(filePreviews || []), ...previews] })
      );
    }
  };

  return (
    <div className={styles.infoContainer}>
      <h1>Darbų fotografijos ir vaizdo įrašai</h1>
      <div className={styles.divider} />
      <p className={styles.inputLabel}>Darbų pavyzdžiai</p>
      <input
        ref={fileInputRef}
        type='file'
        accept='image/*,video/*'
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {(filePreviews?.length ?? 0) > 0 && (
        <>
          <p className={styles.inputLabel}>Failai paruošti įkėlimui:</p>
          <div className={styles.filePreviewContainer}>
            {filePreviews?.map((file, index) => (
              <div key={index}>{file.object.name}</div>
            ))}
          </div>
        </>
      )}
      <div
        className={styles.fileDropZone}
        onClick={handleButtonClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div>
          <img alt='inputFile' src={uploadFileIcon} />
          Čia vilkite arba įkelkite failus
        </div>
      </div>
    </div>
  );
};

const PriceInfoEditor: React.FC = () => {
  const { price, customPrice } = useAppSelector((state) => state.serviceAdjust);
  const dispatch = useAppDispatch();

  const handlePriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        dispatch(setData({ price: undefined }));
      } else {
        dispatch(setData({ price: Number(e.target.value) }));
      }
    },
    [dispatch]
  );

  const handleToggleChange = useCallback(
    (v: boolean) => {
      dispatch(setData({ customPrice: v }));
    },
    [dispatch]
  );

  return (
    <div className={styles.infoContainer}>
      <h1>Kaina</h1>
      <div className={styles.divider} />
      <p className={styles.inputLabel}>Paslaugos kaina (Valandinis)</p>
      <div className={styles.priceInputContainer}>
        <div className={styles.priceIconContainer}>
          <img alt='dollar-circle' src={dollarCircleIcon} />
        </div>
        <input
          className={styles.inputPrice}
          inputMode='numeric'
          pattern=''
          type='number'
          value={price}
          onChange={handlePriceChange}
        />
      </div>

      <div className={styles.toggleContainer}>
        <p>Atlieku paslaugas ne pagal valandinį įkainį</p>
        <ToggleInput checked={customPrice} onChange={handleToggleChange} />
      </div>
    </div>
  );
};

const ExtraServicesInfoEditor: React.FC = () => {
  const { selectedService } = useAppSelector((state) => state.serviceAdjust);
  const dispatch = useAppDispatch();

  const handleSelectService = useCallback(
    (service: ADDITIONAL_SERVICES, toggled?: boolean) => {
      const services: any[] = [...(selectedService ?? [])];

      if (toggled) {
        services.push(service);
      } else {
        _.remove(services, (s) => s === service);
      }

      dispatch(setData({ selectedService: services }));
    },
    [dispatch, selectedService]
  );

  const checkboxArray = useMemo(() => {
    return _.map(
      ADDITIONAL_SERVICES,
      (service, index) =>
        _.isNumber(service) && (
          <div key={`checkbox-${index}`} className={styles.checkboxInput}>
            <Checkbox
              classes={{
                checked: styles.inputChecked,
                root: styles.inputCheckedRoot,
              }}
              value={service}
              checked={_.some(selectedService, (s) => s === service)}
              onClick={() =>
                handleSelectService(
                  service,
                  !_.some(selectedService, (s) => s === service)
                )
              }
            />
            <p>{AdditionalServicesText[service]}</p>
          </div>
        )
    );
  }, [selectedService, handleSelectService]);

  return (
    <div className={styles.infoContainer}>
      <h1>Papildomos paslaugos</h1>
      <div className={styles.divider} />
      <p className={styles.inputLabel}>
        Pasirinkite papildomas teikiamas paslaugas
      </p>
      <Grid2
        container
        className={styles.grid}
        sx={{
          marginLeft: '40px',
          marginBottom: '40px',
        }}
        rowGap={'30px'}
        columnGap={'20px'}
      >
        {checkboxArray}
      </Grid2>
    </div>
  );
};

const ButtonsGrid: React.FC = () => {
  const service = useAppSelector((state) => state.serviceAdjust);
  const dispatch = useAppDispatch();

  const submitHandler = useCallback(async () => {
    const fileData = new FormData();

    fileData.append('id', String(service.serviceId || ''));
    fileData.append('name', service.name || '');
    fileData.append('description', service.description || '');
    // contacts
    fileData.append('phone', service.phone || '');
    fileData.append('email', service.email || '');
    fileData.append('city', service.city || '');
    fileData.append('address', service.address || '');

    // price
    fileData.append('price', String(service.price) || '');
    fileData.append('customPrice', String(service.customPrice));

    // additional services
    service.selectedService?.forEach((s) => {
      fileData.append('selectedService', String(s));
    });

    // files - images - videos
    service.filePreviews?.forEach((file) => {
      fileData.append('files', file.object);
    });

    // calendar data
    fileData.append('calendarData', JSON.stringify(service.calendarData));

    const response = await request(
      {
        method: 'POST',
        url: '/v3/private/service/persist',
        data: fileData,
      },
      dispatch,
      true,
      () => dispatch(setGlobalLoading(false))
    );

    if (response) {
      dispatch(
        displayNotification({
          severity: 'success',
          message: 'Skelbimas sėkmingai išsaugotas',
        })
      );

      // upon update/create - move to all services
      dispatch(
        setCurrentMainPanelSegment({ segment: MainPanelSegments.USER_SERVICES })
      );
    }
  }, [service, dispatch]);

  const resetHandler = useCallback(() => {
    dispatch(resetData());
  }, [dispatch]);

  const adjustCalendarHandler = useCallback(() => {
    dispatch(handleAdjustCalendarModal())
  }, [dispatch]);

  return (
    <div className={styles.infoContainer} style={{ marginBottom: '40px' }}>
      <div className={styles.btnContainer}>
        <button className={styles.btnReset} onClick={resetHandler}>
          Išvalyti duomenis
        </button>
        <button className={styles.btnReset} onClick={adjustCalendarHandler}>
          Koreguoti kalendorių
        </button>
        <button className={styles.btnSubmit} onClick={submitHandler}>
          Išsaugoti
        </button>
      </div>
    </div>
  );
};
